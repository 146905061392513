import { FormHelperText } from '@mui/material';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';

const ProviderDoesNotTakePlanFormHelperText = ({
  carrier,
}: {
  carrier?: FrontEndCarrierRead;
}) => {
  if (!carrier) {
    return null;
  }

  return (
    <FormHelperText variant="standard">
      This provider doesn't take your {carrier.name} plan through Headway, so
      you won't be able to book with them.
    </FormHelperText>
  );
};

export default ProviderDoesNotTakePlanFormHelperText;
