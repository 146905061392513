import { getLocalTimeZone, today } from '@internationalized/date';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { useFlag } from '@headway/feature-flags/react';
import { Button } from '@headway/helix/Button';
import { Card } from '@headway/helix/Card';
import { ContentText } from '@headway/helix/ContentText';
import { DateField } from '@headway/helix/DateField';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { HeadlineText } from '@headway/helix/HeadlineText';
import { IconIdentificationCard } from '@headway/helix/icons/IdentificationCard';
import { IconUserPlus } from '@headway/helix/icons/UserPlus';
import { Item, Section, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import unitedStatesDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { FRONT_END_CARRIERS_EXCLUSION_LIST } from '@headway/shared/FeatureFlags/flagNames';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { IAuthStore, IUiStore, withStores } from '@headway/shared/types/stores';
import { trackEvent } from '@headway/shared/utils/analytics';
import {
  getAvoInsuranceFormContext,
  getCarrierGroupByFunction,
  getFilteredLookupFormCarrierIdList,
  getInFormCarrierWarningStatus,
  InFormCarrierWarningStatus,
  LOOKUP_VALIDATION,
  MemoizedCarriersToOptionsForStateHelper,
  performLookup,
  PerformLookupErrors,
} from '@headway/shared/utils/InsuranceLookupUtils';
import { logWarning } from '@headway/shared/utils/sentry';
import { LogoLoader } from '@headway/ui/LogoLoader';
import { MemberIdValidationGuidance } from '@headway/ui/MemberIdValidationGuidance';

import CarrierNotInStateFormHelperText from './CarrierWarningComponents/CarrierNotInStateFormHelperText';
import CarrierRequiringAuthorizationGuidanceCard from './CarrierWarningComponents/CarrierRequiringAuthorizationGuidanceCard';
import ProviderDoesNotTakePlanFormHelperText from './CarrierWarningComponents/ProviderDoesNotTakePlanFormHelperText';

export type InsuranceCardInfo = {
  firstName: string;
  lastName: string;
  carrierId: number;
  memberId: string;
  groupNumber?: string;
};

enum ConfirmationScreenState {
  SUFFICIENT_INSURANCE_INFO = 'SUFFICIENT_INSURANCE_INFO',
  INSUFFICIENT_INSURANCE_INFO = 'INSUFFICIENT_INSURANCE_INFO',
  EDITING_DETAILS = 'EDITING_DETAILS',
  TELL_US_MORE = 'TELL_US_MORE',
}

export type OCRInsuranceCardConfirmationValues = {
  insuranceCardInfo: InsuranceCardInfo;
  patientState: UnitedStates;
  dateOfBirth: string;
};

export type OCRInsuranceCardScannerConfirmationProps = {
  initialInsuranceCardInfo: Partial<InsuranceCardInfo>;
  insuranceCardInterpretationResultId: number;
  initialPatientState?: UnitedStates;
  submitPreSteps: (
    values: OCRInsuranceCardConfirmationValues
  ) => Promise<boolean>;
  submitPostSteps: (
    values: OCRInsuranceCardConfirmationValues,
    errorStates: PerformLookupErrors | undefined,
    insurance: UserInsuranceRead | undefined
  ) => Promise<void>;
  AuthStore: IAuthStore;
  UiStore: IUiStore;
  providerId?: number;
  providerCarriers: Array<ProviderFrontEndCarrierRead>;
  showInsuranceAuthorizationFields?: (carrier: FrontEndCarrierRead) => void;
  isInsideModal?: boolean;
};

/**
 * this is based heavily on the existing guidance card component, but we want to have two icons and two titles in one block. If we can do this easily in helix, we should swap it out!
 * and if we want to build more components like this we should def add to helix
 */
const CustomInsuranceCardInfoGuidanceCard: React.FC<{
  insuranceCardInfo: Partial<InsuranceCardInfo>;
  carrier?: FrontEndCarrierRead | 'unknown';
}> = ({ insuranceCardInfo, carrier }) => {
  const headingCss = {
    color: theme.color.background['inverted-tertiary'],
    size: '13px',
    fontWeight: 500,
  };
  return (
    <Card>
      <div className="flex flex-col gap-2">
        <div css={{ paddingBottom: '8px' }}>
          <div className="flex">
            <IconUserPlus css={{ marginRight: theme.spacing.x2 }} />
            <ContentText variant="body/medium">
              {insuranceCardInfo.firstName} {insuranceCardInfo.lastName}
            </ContentText>
          </div>
          <div className="flex">
            <div css={{ marginRight: theme.spacing.x7 }} />
            <ContentText variant="body-small">
              If this is not the name for the person receiving care, please edit
              it.
            </ContentText>
          </div>
        </div>
        <div className="flex">
          <IconIdentificationCard css={{ marginRight: theme.spacing.x2 }} />
          <ContentText variant="body/medium">
            {carrier
              ? carrier != 'unknown'
                ? carrier?.name + ' plan'
                : 'Unknown plan'
              : ''}
          </ContentText>
        </div>
        <div className="flex">
          <div css={{ marginRight: theme.spacing.x7 }} />
          <div>
            <div className="w-auto pr-12">
              <span css={headingCss}>Member ID</span>
              <br></br>
              <ContentText variant="body-small">
                {insuranceCardInfo.memberId}
              </ContentText>
            </div>
          </div>
          {insuranceCardInfo.groupNumber && (
            <div className="w-auto">
              <span css={headingCss}>Group number</span>
              <br></br>
              <ContentText variant="body-small">
                {insuranceCardInfo.groupNumber}
              </ContentText>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

type InsuranceCardFormikFormProps = {
  formik: FormikProps<Partial<InsuranceCardInfo>>;
  initialGroupNumber?: string;
  initialPatientState?: UnitedStates;
  providerId?: number;
  carriersById: {
    [key: number]: FrontEndCarrierRead;
  };
  carriers: Array<FrontEndCarrierRead>;
  providerCarriers: Array<ProviderFrontEndCarrierRead>;
  showInsuranceAuthorizationInstructions?: (
    carrier: FrontEndCarrierRead
  ) => void;
  AuthStore: IAuthStore;
};

const InsuranceCardFormikForm = ({
  formik,
  initialGroupNumber,
  initialPatientState,
  providerId,
  providerCarriers,
  carriersById,
  carriers,
  AuthStore,
  showInsuranceAuthorizationInstructions,
}: InsuranceCardFormikFormProps) => {
  const { user } = AuthStore;

  const { values, setFieldValue, isSubmitting } = formik;

  const carrierGroupByFn = initialPatientState
    ? getCarrierGroupByFunction(
        Object.values(carriersById),
        initialPatientState
      )
    : undefined;

  const carriersToOptionsForStateHelper = useRef(
    new MemoizedCarriersToOptionsForStateHelper()
  );

  const matchingProviderCarrierQueryResult =
    useMatchingProviderFrontEndCarrierQuery(
      {
        providerId: providerId!,
        patientFrontEndCarrierId: values.carrierId,
        patientInsuranceMemberId: values.memberId,
        appointmentState: initialPatientState,
        includeHiddenCarriers: false,
      },
      {
        enabled: !!providerId,
      }
    );
  const selectedCarrier = values.carrierId
    ? carriersById[values.carrierId]
    : undefined;
  const args = {
    selectedCarrier,
    selectedState: initialPatientState,
    selectedMemberId: values.memberId ?? '',
    providerCarriers: providerCarriers,
    matchingProviderCarrierQueryResult,
  };
  const inFormCarrierWarningStatuses = getInFormCarrierWarningStatus(args);
  return (
    <>
      <Form id="ocr-insurance-card-scanner-confirmation-editor">
        <div className="grid grid-cols-2 gap-4">
          <div className="peer">
            <FormControl
              component={TextField}
              label="Legal first name"
              name="firstName"
            />
          </div>
          <div className="peer">
            <FormControl
              component={TextField}
              label="Legal last name"
              name="lastName"
            />
          </div>
          <div className="col-span-2 flex flex-col gap-4">
            <FormControl
              component={Select}
              label="Insurance company"
              name="carrierId"
              selectionMode="single"
              searchable={true}
              onSelectionChange={(items: Set<string>) => {
                // this custom onSelectionChange is needed to help it play nice with integers for some reason
                const value = parseInt(Array.from(items)[0]);
                setFieldValue('carrierId', value);
              }}
              selectedKeys={
                values.carrierId ? [values.carrierId.toString()] : []
              }
            >
              {!!carrierGroupByFn
                ? [
                    'Insurance companies we work with',
                    'Other insurance companies',
                  ].map((title) => {
                    return (
                      <Section title={title}>
                        {carriersToOptionsForStateHelper.current
                          .getCarrierIds(carriers, initialPatientState)
                          .filter(
                            (carrierId) => carrierGroupByFn(carrierId) === title
                          )
                          .map((carrierId) => {
                            return (
                              <Item key={carrierId}>
                                {`${carriersById[carrierId].name}`}
                              </Item>
                            );
                          })}
                      </Section>
                    );
                  })
                : carriersToOptionsForStateHelper.current
                    .getCarrierIds(carriers, initialPatientState)
                    .map((carrierId) => {
                      return (
                        <Item key={carrierId}>
                          {`${carriersById[carrierId].name}`}
                        </Item>
                      );
                    })}
            </FormControl>
            {inFormCarrierWarningStatuses.includes(
              InFormCarrierWarningStatus.CARRIER_NOT_IN_STATE
            ) ? (
              <CarrierNotInStateFormHelperText
                carrier={selectedCarrier}
                state={initialPatientState}
              ></CarrierNotInStateFormHelperText>
            ) : inFormCarrierWarningStatuses.includes(
                InFormCarrierWarningStatus.PROVIDER_DOES_NOT_TAKE_PLAN
              ) ? (
              <ProviderDoesNotTakePlanFormHelperText
                carrier={selectedCarrier}
              ></ProviderDoesNotTakePlanFormHelperText>
            ) : null}
            {inFormCarrierWarningStatuses.includes(
              InFormCarrierWarningStatus.CARRIERS_REQUIRING_AUTHORIZATION
            ) && !!showInsuranceAuthorizationInstructions ? (
              <CarrierRequiringAuthorizationGuidanceCard
                carrier={selectedCarrier}
                insuranceAuthorizationPress={() =>
                  showInsuranceAuthorizationInstructions(selectedCarrier!)
                }
              ></CarrierRequiringAuthorizationGuidanceCard>
            ) : (
              <>
                <FormControl
                  component={TextField}
                  label="Member ID"
                  name="memberId"
                />
                <MemberIdValidationGuidance
                  patient={user}
                  memberId={values.memberId || ''}
                  frontEndCarrierId={
                    values.carrierId || ('' as unknown as number)
                  }
                />

                {initialGroupNumber && (
                  <FormControl
                    component={TextField}
                    label="Group Number"
                    name="groupNumber"
                  />
                )}
              </>
            )}
            <Button
              variant="primary"
              disabled={
                !(
                  values.firstName &&
                  values.lastName &&
                  values.carrierId &&
                  values.memberId
                ) ||
                isSubmitting ||
                inFormCarrierWarningStatuses.includes(
                  InFormCarrierWarningStatus.CARRIERS_REQUIRING_AUTHORIZATION
                )
              }
              data-dd-action-name="OCR Confirmation - continue"
              type="submit"
            >
              Continue
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};
const OCRInsuranceCardScannerConfirmationCore: React.FC<
  OCRInsuranceCardScannerConfirmationProps & {
    carriersExclusionIds: Array<number>;
  }
> = ({
  initialInsuranceCardInfo,
  initialPatientState,
  submitPreSteps,
  submitPostSteps,
  carriersExclusionIds,
  AuthStore,
  showInsuranceAuthorizationFields,
  providerId,
  providerCarriers,
  insuranceCardInterpretationResultId,
  isInsideModal,
}) => {
  /**
   * values from insurance card
   */
  const [insuranceCardInfo, setInsuranceCardInfo] = useState<
    Partial<InsuranceCardInfo>
  >(initialInsuranceCardInfo);

  // data states
  const [isCheckingEligibility, setIsCheckingEligibility] =
    useState<boolean>(false);

  const { frontEndCarriers, carriersById, isCarriersLoading } =
    useFrontEndCarriers();
  const carriers = React.useMemo(
    () =>
      getFilteredLookupFormCarrierIdList(
        frontEndCarriers,
        carriersExclusionIds
      ),
    [frontEndCarriers, carriersExclusionIds]
  );

  const getInitialState = (): ConfirmationScreenState => {
    return initialInsuranceCardInfo.firstName &&
      initialInsuranceCardInfo.lastName &&
      initialInsuranceCardInfo.carrierId &&
      initialInsuranceCardInfo.memberId
      ? ConfirmationScreenState.SUFFICIENT_INSURANCE_INFO
      : ConfirmationScreenState.INSUFFICIENT_INSURANCE_INFO;
  };
  // FE UI states
  const [confirmationScreenState, setConfirmationScreenState] =
    useState<ConfirmationScreenState>(getInitialState());

  const initialMatchingProviderCarrierQueryResult =
    useMatchingProviderFrontEndCarrierQuery(
      {
        providerId: providerId!,
        patientFrontEndCarrierId: initialInsuranceCardInfo.carrierId,
        patientInsuranceMemberId: initialInsuranceCardInfo.memberId,
        appointmentState: initialPatientState,
        includeHiddenCarriers: false,
      },
      {
        enabled: !!providerId,
      }
    );

  const patient = AuthStore.user;

  useEffect(() => {
    if (!isCarriersLoading) {
      const initialCarrier = initialInsuranceCardInfo.carrierId
        ? carriersById[initialInsuranceCardInfo.carrierId]
        : undefined;
      const args = {
        selectedCarrier: initialCarrier,
        selectedState: initialPatientState,
        selectedMemberId: initialInsuranceCardInfo.memberId ?? '',
        providerCarriers: providerCarriers,
        matchingProviderCarrierQueryResult:
          initialMatchingProviderCarrierQueryResult,
      };
      const initialInFormCarrierWarningStatuses =
        getInFormCarrierWarningStatus(args);
      if (initialInFormCarrierWarningStatuses.length > 0) {
        setConfirmationScreenState(ConfirmationScreenState.EDITING_DETAILS);
      }
    }
  }, [isCarriersLoading]);

  const insuranceCardEditSubmit = async (
    values: Partial<InsuranceCardInfo>
  ) => {
    if (
      !(
        values.firstName &&
        values.lastName &&
        values.carrierId &&
        values.memberId
      )
    ) {
      // should not reach this warning because the button is disabled
      logWarning(
        'attempting to process insurance card info but insufficient insurance card info'
      );
      return;
    }

    setInsuranceCardInfo(values);
    setConfirmationScreenState(ConfirmationScreenState.TELL_US_MORE);
  };

  const finalSubmit = async (values: {
    patientState?: UnitedStates;
    dateOfBirth?: string;
  }) => {
    if (!values.patientState || !values.dateOfBirth) {
      logWarning(
        'attempting to process insurance card info but patientState or dateOfBirth is not set'
      );
      return;
    }

    trackEvent({
      name: 'Add Insurance Submitted',
      properties: {
        confirmationPageType: 'ocr',
        patientUserId: patient.id,
        insuranceFormContext: getAvoInsuranceFormContext(),
      },
    });

    const completeValues = {
      // we know its no longer a partial at this point
      insuranceCardInfo: insuranceCardInfo as InsuranceCardInfo,
      patientState: values.patientState,
      dateOfBirth: values.dateOfBirth,
    };

    const shouldCheckEligibility = await submitPreSteps(completeValues);

    const lookupResp = await performLookup({
      updateFields: {
        firstName: completeValues.insuranceCardInfo.firstName,
        lastName: completeValues.insuranceCardInfo.lastName,
        memberId: completeValues.insuranceCardInfo.memberId,
        groupNumber: completeValues.insuranceCardInfo.groupNumber,
        dob: completeValues.dateOfBirth,
        carrier: carriersById[completeValues.insuranceCardInfo.carrierId],
        lastSearchedState: completeValues.patientState,
        insuranceCardInterpretationResultId,
      },
      existingUser: patient,
      setUpdatedFrontEndUser: (u: UserRead) => AuthStore.setUser(u),
      shouldCheckEligibility,
      startedCheckingEligibility: () => setIsCheckingEligibility(true),
      carriers: carriers,
    });

    await submitPostSteps(
      completeValues,
      lookupResp.errorStates,
      lookupResp.insurance
    );

    if (
      lookupResp.insurance &&
      lookupResp.insurance?.latestEligibilityLookup?.id
    ) {
      trackEvent({
        name: 'Add Insurance Completed',
        properties: {
          confirmationPageType: 'ocr',
          patientUserId: patient.id,
          userInsuranceId: String(lookupResp.insurance.id),
          eligibilityLookupId: lookupResp.insurance.latestEligibilityLookup.id,
          providerId: providerId,
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
    }
  };

  const isDesktop = useMediaQuery(theme.media.desktop);

  if (isCheckingEligibility) {
    return (
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LogoLoader />
      </div>
    );
  }

  const insuranceDetailsConfirmHeadingMobileNonDesktopModal = (
    <div style={{ paddingBottom: '12px' }}>
      <HeadlineText variant="H4">Confirm your insurance details</HeadlineText>
    </div>
  );

  const insuranceInsufficientInfoHeadingMobileNonDesktopModal = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '12px',
        paddingBottom: '12px',
      }}
    >
      <HeadlineText variant="H4">
        We're missing some details from the images
      </HeadlineText>

      <ContentText variant="body">
        Please add those below so we can provide you an estimate.
      </ContentText>
    </div>
  );

  const insuranceDetailsConfirmHeadingDesktopModal = (
    <div style={{ paddingBottom: '12px' }}>
      <ContentText variant="section-title/medium">
        Confirm your insurance details
      </ContentText>
    </div>
  );

  const insuranceInsufficientInfoHeadingDesktopModal = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '32px',
      }}
    >
      <ContentText variant="section-title/medium">
        We're missing some details from the images
      </ContentText>
      <ContentText variant="body">
        Please add those below so we can provide you an estimate.
      </ContentText>
    </div>
  );
  const insuranceDetailsConfirmHeading =
    isInsideModal && isDesktop
      ? insuranceDetailsConfirmHeadingDesktopModal
      : insuranceDetailsConfirmHeadingMobileNonDesktopModal;
  const insuranceInsufficientInfoHeading =
    isInsideModal && isDesktop
      ? insuranceInsufficientInfoHeadingDesktopModal
      : insuranceInsufficientInfoHeadingMobileNonDesktopModal;

  const editDetailsButton = (
    <Button
      variant="secondary"
      onPress={() => {
        setConfirmationScreenState(ConfirmationScreenState.EDITING_DETAILS);
      }}
      disabled={isCarriersLoading}
      data-dd-action-name="OCR Confirmation - edit details"
    >
      Edit details
    </Button>
  );

  const thisIsCorrectButton = (
    <Button
      variant="primary"
      data-dd-action-name="OCR Confirmation - this is correct"
      disabled={isCarriersLoading}
      onPress={() => {
        setConfirmationScreenState(ConfirmationScreenState.TELL_US_MORE);
      }}
    >
      This is correct
    </Button>
  );

  return (
    // should always have sufficientInsuranceInfo when we get to this screen
    confirmationScreenState === ConfirmationScreenState.TELL_US_MORE ? (
      <>
        <h2>Tell us a bit more about you</h2>
        <ContentText>
          We need this to provide an accurate estimate and process claims for
          you.
        </ContentText>
        <br />
        <br />
        <Formik<{ patientState?: UnitedStates; dateOfBirth?: string }>
          initialValues={{
            patientState: initialPatientState,
            dateOfBirth: undefined,
          }}
          validationSchema={Yup.object().shape({
            dateOfBirth: LOOKUP_VALIDATION.fields.dob,
            patientState: LOOKUP_VALIDATION.fields.state,
          })}
          enableReinitialize={true}
          onSubmit={finalSubmit}
        >
          {({ values, isSubmitting }) => (
            <>
              <Form id="ocr-insurance-card-scanner-confirmation-non-insurance-input">
                <div className="flex flex-col gap-4">
                  <>
                    <FormControl
                      component={DateField}
                      name="dateOfBirth"
                      maxValue={today(getLocalTimeZone())}
                      label="What's your date of birth?"
                    />
                    <FormControl
                      component={Select}
                      label="Which state are you in?"
                      name="patientState"
                      selectionMode="single"
                    >
                      {Object.values(UnitedStates)
                        .sort()
                        .map((s) => (
                          <Item key={s}>{unitedStatesDisplayNames[s]}</Item>
                        ))}
                    </FormControl>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={
                        !values.patientState ||
                        !values.dateOfBirth ||
                        isSubmitting ||
                        isCarriersLoading
                      }
                      data-dd-action-name="OCR Confirmation - estimate cost"
                    >
                      Estimate cost
                    </Button>
                  </>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </>
    ) : confirmationScreenState === ConfirmationScreenState.EDITING_DETAILS ||
      confirmationScreenState ===
        ConfirmationScreenState.INSUFFICIENT_INSURANCE_INFO ? (
      <>
        {confirmationScreenState === ConfirmationScreenState.EDITING_DETAILS &&
          insuranceDetailsConfirmHeading}
        {confirmationScreenState ===
          ConfirmationScreenState.INSUFFICIENT_INSURANCE_INFO &&
          insuranceInsufficientInfoHeading}
        <Formik<Partial<InsuranceCardInfo>>
          initialValues={{
            firstName: insuranceCardInfo.firstName ?? '',
            lastName: insuranceCardInfo.lastName ?? '',
            memberId: insuranceCardInfo.memberId ?? '',
            carrierId: insuranceCardInfo.carrierId ?? undefined,
            // just in case we pass in null instead of undefined, which the validator doesnt like
            groupNumber: insuranceCardInfo.groupNumber ?? undefined,
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            // NOTE: this only includes basic validation for now, if we want to reuse this component for the manual insurance upload flow we should add special character validation (see InsuranceLookupFormManualInput.tsx)
            firstName: LOOKUP_VALIDATION.fields.basicFirstName,
            lastName: LOOKUP_VALIDATION.fields.basicLastName,
            memberId: LOOKUP_VALIDATION.fields.memberId,
            carrierId: LOOKUP_VALIDATION.fields.carrierId,
            groupNumber: LOOKUP_VALIDATION.fields.groupNumber,
          })}
          onSubmit={insuranceCardEditSubmit}
        >
          {(formik) => (
            <InsuranceCardFormikForm
              formik={formik}
              initialPatientState={initialPatientState}
              initialGroupNumber={initialInsuranceCardInfo.groupNumber}
              providerId={providerId}
              providerCarriers={providerCarriers}
              carriersById={carriersById}
              carriers={carriers}
              AuthStore={AuthStore}
              showInsuranceAuthorizationInstructions={
                showInsuranceAuthorizationFields
              }
            />
          )}
        </Formik>
      </>
    ) : (
      <div>
        {insuranceDetailsConfirmHeading}
        <CustomInsuranceCardInfoGuidanceCard
          insuranceCardInfo={insuranceCardInfo}
          carrier={
            insuranceCardInfo.carrierId
              ? (carriersById[insuranceCardInfo.carrierId] ?? undefined)
              : 'unknown'
          }
        ></CustomInsuranceCardInfoGuidanceCard>
        <br></br>
        <br></br>
        {isInsideModal && isDesktop ? (
          <div className="flex justify-end space-x-2">
            {editDetailsButton}
            {thisIsCorrectButton}
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {thisIsCorrectButton}
            {editDetailsButton}
          </div>
        )}
      </div>
    )
  );
};

const OCRInsuranceCardScannerConfirmationWithFlags: React.FC<
  OCRInsuranceCardScannerConfirmationProps
> = (props) => {
  // Check the EXCLUDE_SPECIFIC variation of this feature flag to disable specific front end carriers from appearing in the insurance lookup modal dropdown.
  const carriersExclusionIds = useFlag(FRONT_END_CARRIERS_EXCLUSION_LIST, []);

  return (
    <OCRInsuranceCardScannerConfirmationCore
      {...props}
      carriersExclusionIds={carriersExclusionIds}
    ></OCRInsuranceCardScannerConfirmationCore>
  );
};

const OCRInsuranceCardScannerConfirmation = withStores(
  OCRInsuranceCardScannerConfirmationWithFlags
);
export default OCRInsuranceCardScannerConfirmation;
