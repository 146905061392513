import { useMemo } from 'react';

import {
  FetchQueryOptions,
  InvalidateQueryFilters,
  QueryClient,
  QueryFilters,
  ResetOptions,
  ResetQueryFilters,
  SetDataOptions,
  Updater,
  UseQueryOptions,
} from '@headway/shared/react-query';
import { useQueries, useQueryClient } from '@headway/shared/react-query';
import { throttledNotifyOffline } from '@headway/shared/utils/network';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

/**
 * Type-safe wrapper around queryClient functions for updating cached data, scoped to a particular
 * query key schema.
 */
export interface CacheWrapper<
  QueryKeyArgs extends {},
  QueryFnReturn,
  QueryKey extends readonly unknown[],
> {
  /** Wrapper around queryClient.setQueryData */
  set: (
    queryKeyArgs: QueryKeyArgs,
    updater: Updater<QueryFnReturn | undefined, QueryFnReturn | undefined>,
    options?: SetDataOptions
  ) => void;
  /** Wrapper around queryClient.invalidateQueries */
  invalidate: (queryKeyArgs: QueryKeyArgs) => void;
  /** Calls set, then invalidate. */
  setAndInvalidate: (
    queryKeyArgs: QueryKeyArgs,
    updater: Updater<QueryFnReturn | undefined, QueryFnReturn | undefined>,
    options?: SetDataOptions
  ) => void;
  reset: (
    queryKeyArgs: QueryKeyArgs,
    filters?: ResetQueryFilters,
    options?: ResetOptions
  ) => void;
  /** Wrapper around queryClient.getQueryData */
  get: (queryKeyArgs: QueryKeyArgs) => QueryFnReturn | undefined;
  /** Wrapper around queryClient.cancelQueries */
  cancelQueries: (
    queryKeyArgs: QueryKeyArgs,
    options?: Omit<QueryFilters, 'queryKey'>
  ) => Promise<void>;
  /** Wrapper around queryClient.removeQueries */
  forceRefresh: (
    queryKeyArgs: QueryKeyArgs,
    options?: Omit<QueryFilters, 'queryKey'>
  ) => void;
  prefetch: <T = QueryFnReturn>(
    queryKeyArgs: QueryKeyArgs,
    options?: Omit<
      FetchQueryOptions<QueryFnReturn, unknown, T, QueryKey>,
      'queryKey' | 'queryFn'
    >
  ) => Promise<void>;
}

/**
 * Creates a set of hooks which wrap `useQueries`, with the intent of allowing the creation of data
 * fetching hooks depending on a single endpoint with minimal boilerplate. It returns both a "single"
 * hook meant for fetching a single query key, and a "list" hook meant for fetching a dynamic number
 * of query keys. The hooks support passing `UseQueryOptions` objects as well as performing automatic
 * error logging. It also returns a type-safe wrapper around `useQueryClient` meant for updating
 * the cached data associated with these hooks.
 *
 * @param queryKeyFn A function which receives the query key arguments and should return the query
 * key that react-query should use.
 * @param queryDataFn See the `queryFn` argument of `useQuery`.
 * @param defaultEnabled A function which receives the query key arguments and should return whether
 * the query should be enabled. If the consumer also passes a custom `enabled` value in the query
 * options, the two values are combined using a logical AND.
 * @param getErrorMessage A function which is called whenever queryDataFn results in an error. It
 * receives the query key arguments and the error object, and should return an error message which
 * will be displayed to the user in a toast.
 */
export const createBasicApiHooks = <
  QueryKeyArgs extends {},
  QueryKey extends readonly unknown[],
  QueryFnReturn,
>(
  queryKeyFn: (queryKeyArgs: QueryKeyArgs) => QueryKey,
  queryDataFn: (queryKeyArgs: QueryKeyArgs) => Promise<QueryFnReturn>,
  defaultEnabled: (queryKeyArgs: QueryKeyArgs) => boolean,
  getErrorMessage: (queryKeyArgs: QueryKeyArgs, err: unknown) => string
) => {
  const useListQuery = <T = QueryFnReturn>(
    queryArgs: Array<{
      queryKeyArgs: QueryKeyArgs;
      options?: UseQueryOptions<QueryFnReturn, unknown, T, QueryKey>;
    }>
  ) =>
    useQueries({
      queries: queryArgs.map(({ queryKeyArgs, options = {} }) => ({
        ...options,
        queryKey: queryKeyFn(queryKeyArgs),
        queryFn: async () => {
          try {
            return await queryDataFn(queryKeyArgs);
          } catch (err: any) {
            if (!window.navigator.onLine) {
              throttledNotifyOffline();
            } else {
              if (process.env.NODE_ENV !== 'production') {
                const message = getErrorMessage(queryKeyArgs, err);
                notifyError(message);
              }
              logException(err);
            }
            throw err;
          }
        },
        enabled: options.enabled !== false && defaultEnabled(queryKeyArgs),
      })),
    });

  const singleQuery = <T = QueryFnReturn>(
    queryKeyArgs: QueryKeyArgs,
    queryClient: QueryClient,
    options: UseQueryOptions<QueryFnReturn, unknown, T, QueryKey> = {}
  ) => {
    return queryClient.fetchQuery(
      queryKeyFn(queryKeyArgs),
      async () => {
        try {
          return await queryDataFn(queryKeyArgs);
        } catch (err: any) {
          if (!window.navigator.onLine) {
            throttledNotifyOffline();
          } else {
            if (process.env.NODE_ENV !== 'production') {
              const message = getErrorMessage(queryKeyArgs, err);
              notifyError(message);
            }
            logException(err);
          }
          throw err;
        }
      },
      options
    );
  };

  const useSingleQuery = <T = QueryFnReturn>(
    queryKeyArgs: QueryKeyArgs,
    options: UseQueryOptions<QueryFnReturn, unknown, T, QueryKey> = {}
  ) => {
    return useListQuery([{ queryKeyArgs, options }])[0];
  };

  const useCachedQuery: () => CacheWrapper<
    QueryKeyArgs,
    QueryFnReturn,
    QueryKey
  > = () => {
    const queryClient = useQueryClient();
    return useMemo(() => {
      const set = (
        queryKeyArgs: QueryKeyArgs,
        updater: Updater<QueryFnReturn | undefined, QueryFnReturn | undefined>,
        options?: SetDataOptions
      ) => {
        queryClient.setQueryData<QueryFnReturn | undefined>(
          queryKeyFn(queryKeyArgs),
          updater,
          options
        );
      };
      const invalidate = (
        queryKeyArgs: QueryKeyArgs,
        filters?: InvalidateQueryFilters
      ) => queryClient.invalidateQueries(queryKeyFn(queryKeyArgs), filters);

      return {
        set,
        invalidate,
        setAndInvalidate: (
          queryKeyArgs: QueryKeyArgs,
          updater: Updater<
            QueryFnReturn | undefined,
            QueryFnReturn | undefined
          >,
          setDataOptions?: SetDataOptions,
          invalidateFilters?: InvalidateQueryFilters
        ) => {
          set(queryKeyArgs, updater, setDataOptions);
          invalidate(queryKeyArgs, invalidateFilters);
        },
        reset: (
          queryKeyArgs: QueryKeyArgs,
          filters?: ResetQueryFilters,
          options?: ResetOptions
        ) =>
          queryClient.resetQueries(queryKeyFn(queryKeyArgs), filters, options),
        get: (
          queryKeyArgs: QueryKeyArgs,
          filters?: Omit<QueryFilters, 'queryKey'>
        ) =>
          queryClient.getQueryData<QueryFnReturn>(
            queryKeyFn(queryKeyArgs),
            filters
          ),
        cancelQueries: (
          queryKeyArgs: QueryKeyArgs,
          options: Omit<QueryFilters, 'queryKey'> = {}
        ) =>
          queryClient.cancelQueries({
            ...options,
            queryKey: queryKeyFn(queryKeyArgs),
          }),
        forceRefresh: (
          queryKeyArgs: QueryKeyArgs,
          options: Omit<QueryFilters, 'queryKey'> = {}
        ) =>
          queryClient.removeQueries({
            ...options,
            queryKey: queryKeyFn(queryKeyArgs),
          }),
        prefetch: <T = QueryFnReturn>(
          queryKeyArgs: QueryKeyArgs,
          options: Omit<
            FetchQueryOptions<QueryFnReturn, unknown, T, QueryKey>,
            'queryKey' | 'queryFn'
          > = {}
        ) =>
          queryClient.prefetchQuery({
            ...options,
            queryKey: queryKeyFn(queryKeyArgs),
            queryFn: () => queryDataFn(queryKeyArgs),
          }),
      };
    }, [queryClient]);
  };

  return {
    useSingleQuery,
    useListQuery,
    useCachedQuery,
    singleQuery,
  } as const;
};
