import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';

const CarrierRequiringAuthorizationGuidanceCard = ({
  carrier,
  insuranceAuthorizationPress,
  layout,
}: {
  carrier?: FrontEndCarrierRead;
  insuranceAuthorizationPress: () => void;
  layout?: 'vertical' | 'horizontal';
}) => {
  if (!carrier) {
    return null;
  }

  return (
    <GuidanceCard variant="error" layout={layout}>
      <ContentText>
        You need authorization from {carrier.name + ' '}
        before booking sessions. If you already have a referral, call us at
        (510) 495-1728. Otherwise, learn{' '}
        <Button variant="link" onPress={insuranceAuthorizationPress}>
          how to request a referral.
        </Button>
      </ContentText>
    </GuidanceCard>
  );
};

export default CarrierRequiringAuthorizationGuidanceCard;
