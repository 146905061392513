import { UserRead } from '@headway/api/models/UserRead';
import { useFlag } from '@headway/feature-flags/react';

import { NEW_CONSENT_FORMS_LAUNCH_DATE } from '../FeatureFlags/flagNames';
import { logException } from './sentry';

export const isProviderUser = (user: UserRead): boolean => {
  return !!user?.providerId || !!user?.groupPracticeUsers?.length;
};

export const useUserRequiresNewConsentForms = (
  userCreatedOn: string | undefined
) => {
  // Fetch the launch date from the feature flag
  const launchDateStr = useFlag(NEW_CONSENT_FORMS_LAUNCH_DATE, false);

  // If the flag is not set or is empty OR userCreatedOn is invalid, return False
  if (!launchDateStr || !userCreatedOn) {
    return false;
  }

  try {
    const launchDate = new Date(launchDateStr);
    const userCreatedOnDate = new Date(userCreatedOn);

    // Handle invalid date values
    if (isNaN(launchDate.getTime()) || isNaN(userCreatedOnDate.getTime())) {
      return false;
    }

    // If the user was created after the launch date, return True
    return userCreatedOnDate >= launchDate;
  } catch (error) {
    // If there is an error, log it and return False
    logException(error);
    return false;
  }
};
