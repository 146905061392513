import { UnitedStates } from '@headway/api/models/UnitedStates';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

interface UseMatchingProviderFrontEndCarrierQueryKeyArgs {
  providerId: number;
  patientUserId?: number;
  patientFrontEndCarrierId?: number;
  patientInsuranceMemberId?: string;
  appointmentId?: number;
  isTelehealthAppointment?: boolean;
  appointmentState?: UnitedStates;
  includeHiddenCarriers?: boolean;
}

const getUseMatchingProviderFrontEndCarrierQueryKey = ({
  providerId,
  patientUserId,
  patientFrontEndCarrierId,
  patientInsuranceMemberId,
  appointmentId,
  isTelehealthAppointment,
  appointmentState,
  includeHiddenCarriers,
}: UseMatchingProviderFrontEndCarrierQueryKeyArgs) => [
  'matching-provider-front-end-carrier',
  providerId,
  patientUserId,
  patientFrontEndCarrierId,
  patientInsuranceMemberId,
  appointmentId,
  isTelehealthAppointment,
  appointmentState,
  includeHiddenCarriers,
];

const {
  singleQuery: matchingProviderFrontEndCarrierQuery,
  useSingleQuery: useMatchingProviderFrontEndCarrierQuery,
} = createBasicApiHooks(
  getUseMatchingProviderFrontEndCarrierQueryKey,
  async ({
    providerId,
    patientUserId,
    patientFrontEndCarrierId,
    patientInsuranceMemberId,
    appointmentId,
    isTelehealthAppointment,
    appointmentState,
    includeHiddenCarriers,
  }: UseMatchingProviderFrontEndCarrierQueryKeyArgs) => {
    return ProviderApi.getMatchingProviderFrontEndCarrier(providerId, {
      patient_user_id: patientUserId,
      patient_front_end_carrier_id: patientFrontEndCarrierId,
      patient_insurance_member_id: patientInsuranceMemberId,
      appointment_id: appointmentId,
      is_telehealth_appointment: isTelehealthAppointment,
      appointment_state: appointmentState,
      include_hidden_carriers: includeHiddenCarriers,
    });
  },
  ({ patientUserId, patientFrontEndCarrierId, patientInsuranceMemberId }) =>
    !!patientUserId ||
    (!!patientFrontEndCarrierId && !!patientInsuranceMemberId),
  () => 'Failed to fetch matching provider front end carrier'
);
export {
  matchingProviderFrontEndCarrierQuery,
  useMatchingProviderFrontEndCarrierQuery,
};
