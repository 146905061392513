import { HeadlineText } from '@headway/helix/HeadlineText';

type BookAppointmentHelixVariantHeadlineProps = {
  children: React.ReactNode;
};

/**
 * This component is used to ensure style consistency for page titles across the helix variants of the
 * Book Appointment flow screens.
 */
const BookAppointmentHelixVariantHeadline = ({
  children,
}: BookAppointmentHelixVariantHeadlineProps) => {
  return (
    <div>
      <div className="desktop:block hidden">
        <HeadlineText variant="H2">{children}</HeadlineText>
      </div>
      <div className="desktop:hidden">
        <HeadlineText variant="H3">{children}</HeadlineText>
      </div>
    </div>
  );
};

export default BookAppointmentHelixVariantHeadline;
