import React, { useEffect, useRef, useState } from 'react';

import { HardcodedInsuranceCardInterpretationApi } from '@headway/api/hardcoded-resources/HardcodedInsuranceCardInterpretationApi';
import { InterpretInsuranceCardResponse } from '@headway/api/models/InterpretInsuranceCardResponse';
import ScanningCardScreen, {
  checkingImageReadabilityContent,
} from '@headway/benefits/OCRInsuranceCardLoadingDisplays';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { Halfsheet, HalfsheetFooter } from '@headway/helix/Halfsheet';
import { NumberedList, NumberedListItem } from '@headway/helix/List';
import { Modal } from '@headway/helix/Modal';
import { Image } from '@headway/patient/components/Image/Image';
import { SelectorError } from '@headway/shared/types/insuranceCapture';
import { IAuthStore, IUiStore, withStores } from '@headway/shared/types/stores';
import { trackEvent } from '@headway/shared/utils/analytics';
import {
  AnalyticsOCRQualityCheckFailureReason,
  getAnalyticsOCRFailureReason,
  getAvoInsuranceFormContext,
  getBlobFromString,
  isInterpretationResponseUnreadable,
} from '@headway/shared/utils/InsuranceLookupUtils';

import framedInsuranceCardBack from '../../assets/img/framed-insurance-card-back.png';
import framedInsuranceCardFront from '../../assets/img/framed-insurance-card-front.png';
import CameraWithOverlay, {
  CameraWithOverlayHandle,
} from './InsuranceCardCameraOverlay';

interface InsuranceInterpretationCameraCaptureFlowProps {
  initialFrontImage?: string;
  clearInputModeSelection: (selectorError?: SelectorError) => void;
  handleInterpretation: (
    interpretationResponse: InterpretInsuranceCardResponse
  ) => void;
  redirectToManual: () => void;
  AuthStore: IAuthStore;
  UiStore: IUiStore;
}

enum CameraInputPageState {
  AWAITING_FRONT_IMAGE = 'AWAITING_FRONT_IMAGE',
  AWAITING_BACK_IMAGE = 'AWAITING_BACK_IMAGE',
  CHECKING_READABILITY = 'CHECKING_READABILITY',
  UNREADABLE_BACK_IMAGE = 'UNREADABLE_BACK_IMAGE',
  UNREADABLE_FRONT_IMAGE = 'UNREADABLE_FRONT_IMAGE',
  AWAITING_INTERPRETATION = 'AWAITING_INTERPRETATION',
}

const InsuranceInterpretationCameraCaptureFlowCore: React.FC<
  InsuranceInterpretationCameraCaptureFlowProps
> = ({
  handleInterpretation,
  initialFrontImage,
  AuthStore,
  redirectToManual,
  clearInputModeSelection,
}) => {
  const [blobImages, setBlobImages] = useState<{
    front: Blob | null;
    back: Blob | null;
  }>({ front: null, back: null });

  const [pageState, setPageState] = useState<CameraInputPageState | null>(null);
  const [isPageReady, setIsPageReady] = useState<boolean>(false);
  const cameraRef = useRef<CameraWithOverlayHandle | null>(null);

  const takePhoto = () => {
    if (cameraRef.current) {
      cameraRef.current.capturePhoto();
    }
  };

  const retakePhoto = () => {
    if (pageState === CameraInputPageState.UNREADABLE_BACK_IMAGE) {
      setPageState(CameraInputPageState.AWAITING_BACK_IMAGE);
    } else if (pageState === CameraInputPageState.UNREADABLE_FRONT_IMAGE) {
      setPageState(CameraInputPageState.AWAITING_FRONT_IMAGE);
    }
  };

  useEffect(() => {
    if (cameraRef.current) {
      setPageState(
        initialFrontImage
          ? CameraInputPageState.AWAITING_BACK_IMAGE
          : CameraInputPageState.AWAITING_FRONT_IMAGE
      );
    }
    if (initialFrontImage) {
      try {
        getBlobFromString(initialFrontImage).then((frontImageBlob) =>
          setBlobImages((prevState) => ({
            ...prevState,
            ['front']: frontImageBlob,
          }))
        );
      } catch (error) {
        clearInputModeSelection(SelectorError.UNPARSEABLE_IMAGE);
      }
    }
  }, []);

  const resetCamera = () => {
    if (cameraRef.current) {
      cameraRef.current.clear();
    }
  };

  useEffect(() => {
    const halfsheetElement = document.querySelector('.hlx-halfsheet');
    if (halfsheetElement instanceof HTMLElement) {
      halfsheetElement.style.height = '45%';
    }

    if (
      pageState === CameraInputPageState.AWAITING_FRONT_IMAGE ||
      pageState === CameraInputPageState.AWAITING_BACK_IMAGE
    ) {
      resetCamera(); // Clear or reset the camera ref on state change
    }
  }, [pageState]);

  const halfsheetContent = () => {
    if (
      pageState == CameraInputPageState.AWAITING_BACK_IMAGE ||
      pageState == CameraInputPageState.AWAITING_FRONT_IMAGE
    ) {
      const imageSide =
        pageState == CameraInputPageState.AWAITING_FRONT_IMAGE
          ? 'Front'
          : 'Back';
      const instructionImage =
        pageState == CameraInputPageState.AWAITING_FRONT_IMAGE
          ? framedInsuranceCardFront
          : framedInsuranceCardBack;
      return (
        <Halfsheet
          aria-label="Photo Instructions"
          isOpen={true}
          shouldCloseOnInteractOutside={(e: Element) => {
            return false;
          }}
          onDismiss={() => {}}
        >
          <div style={styles.photoInstructions}>
            <Image
              src={instructionImage}
              alt="Insurance card"
              css={{ width: '40%' }}
            />
            <ContentText variant="body-large/medium">
              {imageSide} side of your insurance card
            </ContentText>
            <ContentText variant="body-small">
              Position your card in the frame above. Make sure it is well-lit
              with no glare.
            </ContentText>
          </div>
          <HalfsheetFooter>
            <Button
              variant="primary"
              size="large"
              onPress={takePhoto}
              disabled={!isPageReady}
              data-dd-action-name="OCR Camera Flow - Take photo"
            >
              Take photo
            </Button>
          </HalfsheetFooter>
        </Halfsheet>
      );
    } else if (pageState == CameraInputPageState.CHECKING_READABILITY) {
      return (
        <Halfsheet
          aria-label="Photo loading"
          isOpen={true}
          shouldCloseOnInteractOutside={(e: Element) => {
            return false;
          }}
          onDismiss={() => {}}
        >
          {checkingImageReadabilityContent}
        </Halfsheet>
      );
    } else if (
      pageState == CameraInputPageState.UNREADABLE_FRONT_IMAGE ||
      pageState == CameraInputPageState.UNREADABLE_BACK_IMAGE
    ) {
      return (
        <Halfsheet
          aria-label="Captured photo unreadable"
          isOpen={true}
          shouldCloseOnInteractOutside={(e: Element) => {
            return false;
          }}
          onDismiss={() => {}}
        >
          <div className="flex flex-col gap-1">
            <ContentText variant="body-large/medium">
              We're unable to read this image
            </ContentText>
            <ContentText variant="body">Please make sure:</ContentText>
            <NumberedList>
              <NumberedListItem>
                <ContentText variant="body-small">
                  The text on the card is sharp and readable
                </ContentText>
              </NumberedListItem>

              <NumberedListItem>
                <ContentText variant="body-small">
                  There's no glare, overly bright spots or shadows
                </ContentText>
              </NumberedListItem>

              <NumberedListItem>
                <ContentText variant="body-small">
                  The entire card fits within the frame and is aligned with the
                  corners.
                </ContentText>
              </NumberedListItem>
            </NumberedList>
          </div>
          <HalfsheetFooter>
            <Button
              variant="primary"
              size="large"
              onPress={retakePhoto}
              disabled={!isPageReady}
              data-dd-action-name="OCR Camera Flow - Retake photo"
            >
              Retake photo
            </Button>
            <Button
              variant="secondary"
              size="large"
              onPress={redirectToManual}
              disabled={!isPageReady}
              data-dd-action-name="OCR Camera Flow - Enter manually instead"
            >
              Enter manually instead
            </Button>
          </HalfsheetFooter>
        </Halfsheet>
      );
    } else {
      return null; //shouldn't reach this state
    }
  };

  const startReadabilityCheck = (image: string) => {
    const imageSide =
      pageState == CameraInputPageState.AWAITING_FRONT_IMAGE ? 'front' : 'back';
    setPageState(CameraInputPageState.CHECKING_READABILITY);
    completeReadabilityCheck(image, imageSide);
  };

  const completeReadabilityCheck = async (
    image: string,
    imageSide: 'front' | 'back'
  ) => {
    let qualityCheckSucceeded: boolean = false;
    let qualityCheckFailureReason:
      | AnalyticsOCRQualityCheckFailureReason
      | undefined;
    try {
      const blobImage = await getBlobFromString(image);
      const query = { owner_id: AuthStore.user.id };
      const imageReadabilityResult =
        await HardcodedInsuranceCardInterpretationApi.validateInsuranceCardImage(
          blobImage,
          query
        );
      const isImageReadable = imageReadabilityResult.isValidImage;
      if (isImageReadable) {
        setBlobImages((prevState) => ({
          ...prevState,
          [imageSide]: blobImage,
        }));
        if (imageSide == 'front') {
          setPageState(CameraInputPageState.AWAITING_BACK_IMAGE);
          qualityCheckSucceeded = true;
        } else {
          setPageState(CameraInputPageState.AWAITING_INTERPRETATION);
          const interpretationResponse =
            await HardcodedInsuranceCardInterpretationApi.extractInsuranceCardInfo(
              blobImage,
              blobImages['front'],
              query
            );

          if (isInterpretationResponseUnreadable(interpretationResponse)) {
            clearInputModeSelection(SelectorError.UNPARSEABLE_IMAGE);
            qualityCheckFailureReason = 'unparseable_model_response';
          } else {
            handleInterpretation(interpretationResponse);
            qualityCheckSucceeded = true;
          }
        }
      } else {
        if (
          imageReadabilityResult.invalidReasons &&
          imageReadabilityResult.invalidReasons.length > 0
        ) {
          qualityCheckFailureReason = getAnalyticsOCRFailureReason(
            imageReadabilityResult.invalidReasons[0]
          );
        }
        if (imageSide == 'front') {
          setPageState(CameraInputPageState.UNREADABLE_FRONT_IMAGE);
        } else {
          setPageState(CameraInputPageState.UNREADABLE_BACK_IMAGE);
        }
      }
    } catch (error) {
      clearInputModeSelection(SelectorError.UNPARSEABLE_IMAGE);
    } finally {
      trackEvent({
        name: 'OCR Quality Check Completed',
        properties: {
          patientUserId: AuthStore.user.id,
          cardSide: imageSide,
          ocrType: 'camera',
          qualityCheckSucceeded,
          qualityCheckFailureReason: qualityCheckSucceeded
            ? 'no_failure'
            : (qualityCheckFailureReason ?? 'unknown'),
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
    }
  };

  return pageState === CameraInputPageState.AWAITING_INTERPRETATION ? (
    <Modal
      aria-label="Extracting insurance card information"
      isOpen={true}
      variant="fullscreen"
      isDismissable={false}
      title=""
    >
      <ScanningCardScreen isMobile={true} />
    </Modal>
  ) : (
    <Modal
      title="Take photos"
      isOpen={true}
      variant="fullscreen"
      onDismiss={clearInputModeSelection}
    >
      <div style={styles.container}>
        <div style={styles.camera}>
          <CameraWithOverlay
            ref={cameraRef}
            onImageCapture={startReadabilityCheck}
            handleCameraError={clearInputModeSelection}
            onCameraReady={(isReady: boolean) => {
              setIsPageReady(isReady);
            }}
          />
        </div>
        {halfsheetContent()}
      </div>
    </Modal>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
  },
  camera: {
    position: 'relative',
    objectFit: 'fill',
    top: '0',
    left: '0',
    bottom: '50%',
    width: '100%',
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  photoInstructions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '8px',
  },
};
const InsuranceInterpretationCameraCaptureFlow = withStores(
  InsuranceInterpretationCameraCaptureFlowCore
);
export default InsuranceInterpretationCameraCaptureFlow;
