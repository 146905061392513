import React from 'react';

import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { HeadlineText } from '@headway/helix/HeadlineText';
import { IconSealCheck } from '@headway/helix/icons/SealCheck';
import { theme } from '@headway/helix/theme';
import { ImageSquare } from '@headway/icons';
import { Image } from '@headway/patient/components/Image/Image';
import { InsuranceLookupInputMode } from '@headway/shared/types/insuranceCapture';
import { SelectorError } from '@headway/shared/types/insuranceCapture';
import { IAuthStore, IUiStore, withStores } from '@headway/shared/types/stores';
import { formatPatientName } from '@headway/shared/utils/patient';

import insuranceCardFront from '../../assets/img/insurance-card-front.png';
import SelectorErrorCard from './SelectorErrorCard';

interface InsuranceLookupInputModeSelectorProps {
  setModeSelection: (mode: InsuranceLookupInputMode) => void;
  selectorError?: SelectorError;
  includeHeading?: boolean;
  includeFooter?: boolean;
  AuthStore: IAuthStore;
  UiStore: IUiStore;
}

export const InsuranceLookupInputModeSelectorCore: React.FC<
  InsuranceLookupInputModeSelectorProps
> = ({
  setModeSelection,
  selectorError,
  includeHeading,
  includeFooter,
  AuthStore,
}) => {
  const firstName = formatPatientName(AuthStore.user, {
    firstNameOnly: true,
  });
  return (
    <>
      <div className="flex flex-col gap-2">
        {includeHeading && (
          <HeadlineText variant="H4">
            {firstName ? `${firstName}, l` : `L`}et's estimate your cost
          </HeadlineText>
        )}
        <ContentText variant="body-large">
          Take photos or upload images of your insurance card. We'll need both
          sides of your card.
        </ContentText>
        {selectorError && <SelectorErrorCard selectorError={selectorError} />}
        <Image
          src={insuranceCardFront}
          alt="Insurance card"
          sizes="(max-width: 768px) 375px, 850px"
          css={{
            width: '100%',
            paddingTop: theme.spacing.x5,
            paddingBottom: theme.spacing.x5,
          }}
        />
        <div className="flex flex-col gap-4 pb-4">
          <Button
            onPress={() => {
              setModeSelection(InsuranceLookupInputMode.UPLOAD);
            }}
            data-dd-action-name="OCR Flow - Take photos or upload images"
          >
            <span className="flex items-center justify-center gap-2">
              <ImageSquare fill="#FFFFFF" width={24} height={24} />
              Take photos or upload images
            </span>
          </Button>
          <Button
            onPress={() => {
              setModeSelection(InsuranceLookupInputMode.MANUAL);
            }}
            variant="secondary"
            data-dd-action-name="OCR Flow - Enter insurance details manually instead"
          >
            Enter insurance details manually instead
          </Button>
        </div>
        {includeFooter && (
          <div className="flex flex-col items-center gap-2 pt-12 text-center">
            <IconSealCheck width={20} height={20} />
            <ContentText variant="body-large">
              Most of our patients find their final cost matches our estimate.
            </ContentText>
          </div>
        )}
      </div>
    </>
  );
};

export const InsuranceLookupInputModeSelector = withStores(
  InsuranceLookupInputModeSelectorCore
);
