/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum CarrierLineOfBusiness {
    MEDICARE = 'MEDICARE',
    MEDICAID = 'MEDICAID',
    COMMERCIAL = 'COMMERCIAL',
    EAP = 'EAP'
}


export const CarrierLineOfBusinessMetadata: EnumMetadata<CarrierLineOfBusiness> = {
    name: 'CarrierLineOfBusiness',
    values: {
            MEDICARE: 'MEDICARE',
            MEDICAID: 'MEDICAID',
            COMMERCIAL: 'COMMERCIAL',
            EAP: 'EAP'
    }
}
