import React, { useCallback, useState } from 'react';

import { HardcodedInsuranceCardInterpretationApi } from '@headway/api/hardcoded-resources/HardcodedInsuranceCardInterpretationApi';
import { InterpretInsuranceCardResponse } from '@headway/api/models/InterpretInsuranceCardResponse';
import ScanningCardScreen, {
  checkingImageReadabilityContent,
} from '@headway/benefits/OCRInsuranceCardLoadingDisplays';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { NumberedList, NumberedListItem } from '@headway/helix/List';
import { Modal, ModalContent } from '@headway/helix/Modal';
import { theme as HelixTheme } from '@headway/helix/theme';
import { ImageSquare } from '@headway/icons';
import { Camera } from '@headway/icons';
import { Image } from '@headway/patient/components/Image/Image';
import { BOTTOM_BUTTON_CSS } from '@headway/shared/constants/insuranceLookup';
import { SelectorError } from '@headway/shared/types/insuranceCapture';
import { IAuthStore, IUiStore, withStores } from '@headway/shared/types/stores';
import { trackEvent } from '@headway/shared/utils/analytics';
import {
  AnalyticsOCRQualityCheckFailureReason,
  CLIENT_SIDE_FILE_SIZE_LIMIT,
  fileTypePermitted,
  getAnalyticsOCRFailureReason,
  getAvoInsuranceFormContext,
  getBlobFromString,
  hasUploadTooLargeMessage,
  isInterpretationResponseUnreadable,
  maybeCompressImageWithTimeout,
} from '@headway/shared/utils/InsuranceLookupUtils';
import { logException, logWarning } from '@headway/shared/utils/sentry';
import { theme } from '@headway/ui/theme';

import framedInsuranceCardBack from '../../assets/img/framed-insurance-card-back.png';
import framedInsuranceCardFront from '../../assets/img/framed-insurance-card-front.png';
import insuranceCardBack from '../../assets/img/insurance-card-back.png';
import insuranceCardFront from '../../assets/img/insurance-card-front.png';
import InsuranceCardPhotoEditor from './InsuranceCardPhotoEditor';

interface InsuranceInterpretationImageUploadFlowProps {
  handleInterpretation: (
    interpretationResponse: InterpretInsuranceCardResponse
  ) => void;
  clearInputModeSelection: (inputModeError?: SelectorError) => void;
  redirectToManual: () => void;
  AuthStore: IAuthStore;
  UiStore: IUiStore;
}

const InsuranceInterpretationImageUploadFlowCore: React.FC<
  InsuranceInterpretationImageUploadFlowProps
> = ({
  handleInterpretation,
  clearInputModeSelection,
  redirectToManual,
  AuthStore,
}) => {
  const [editedFrontImage, setEditedFrontImage] = useState<string | null>(null);
  const [editedBackImage, setEditedBackImage] = useState<string | null>(null);

  const [error, setError] = useState('');
  const [readabilityError, setReadabilityError] = useState<string | null>('');
  const [isCheckingReadability, setIsCheckingReadability] = useState(false);
  const [isWaitingOnImage, setIsWaitingOnImage] = useState(false);
  const [
    isWaitingOnInterpretationResponse,
    setIsWaitingOnInterpretationResponse,
  ] = useState<boolean>(false);
  const [editorCurrentImage, setEditorCurrentImage] = useState<string | null>(
    null
  );
  const [lastSelectedInputType, setLastSelectedInputType] = useState<
    'camera' | 'upload' | null
  >(null);

  const [processEditedImage, setProcessEditedImage] = useState<
    () => Promise<string | undefined>
  >(async () => {
    return undefined;
  });

  const triggerCameraInput = () => {
    trackEvent({
      name: 'OCR Side Specific Photo Button Clicked',
      properties: {
        patientUserId: AuthStore.user.id,
        cardSide: hasEditedFirstImage ? 'back' : 'front',
        ocrType: 'camera',
        insuranceFormContext: getAvoInsuranceFormContext(),
      },
    });
    setLastSelectedInputType('camera');
    document!.getElementById('ocrCameraInput')!.click();
  };

  const triggerFileInput = () => {
    trackEvent({
      name: 'OCR Side Specific Photo Button Clicked',
      properties: {
        patientUserId: AuthStore.user.id,
        cardSide: hasEditedFirstImage ? 'back' : 'front',
        ocrType: 'upload',
        insuranceFormContext: getAvoInsuranceFormContext(),
      },
    });
    setLastSelectedInputType('upload');
    document!.getElementById('ocrFileInput')!.click();
  };

  const setEditedImage = (updatedImage: string, side: 'front' | 'back') => {
    setEditorCurrentImage(null);
    if (side === 'front') {
      setEditedFrontImage(updatedImage);
    } else {
      setEditedBackImage(updatedImage);
    }
  };

  const handleProcessEditedImage = useCallback(
    (updateFn: () => Promise<string | undefined>) => {
      setProcessEditedImage(() => updateFn);
    },
    []
  );

  const doQualityCheck = async (
    image: string
  ): Promise<{
    successful: boolean;
    failureReason?: AnalyticsOCRQualityCheckFailureReason;
  }> => {
    setIsCheckingReadability(true);

    setEditorCurrentImage(image);
    const imageObj = await maybeCompressImageWithTimeout(image);
    // re-set it in case it was compressed
    setEditorCurrentImage(imageObj.url);
    const query = { owner_id: AuthStore.user.id };
    const imageReadabilityResult =
      await HardcodedInsuranceCardInterpretationApi.validateInsuranceCardImage(
        imageObj.blob,
        query
      );

    setIsCheckingReadability(false);

    let successful: boolean = false;
    let failureReason: AnalyticsOCRQualityCheckFailureReason | undefined;
    if (imageReadabilityResult.isValidImage) {
      if (!hasEditedFirstImage) {
        setEditedImage(imageObj.url, 'front');
        setReadabilityError(null);
        successful = true;
      } else if (!hasEditedSecondImage) {
        setEditedImage(imageObj.url, 'back');
        setReadabilityError(null);
        setIsWaitingOnInterpretationResponse(true);
        const frontImageBlob = await getBlobFromString(editedFrontImage);
        const backImageBlob = await getBlobFromString(imageObj.url);
        const interpretationResponse =
          await HardcodedInsuranceCardInterpretationApi.extractInsuranceCardInfo(
            backImageBlob,
            frontImageBlob,
            query
          );

        if (isInterpretationResponseUnreadable(interpretationResponse)) {
          clearInputModeSelection(SelectorError.UNPARSEABLE_IMAGE);
          failureReason = 'unparseable_model_response';
        } else {
          handleInterpretation(interpretationResponse);
          successful = true;
        }
        setIsWaitingOnInterpretationResponse(false);
      }
    } else if (
      imageReadabilityResult.invalidReasons &&
      imageReadabilityResult.invalidReasons.length > 0
    ) {
      const invalidReason = imageReadabilityResult.invalidReasons[0]; // take the first for now
      setReadabilityError(invalidReason);
      failureReason = getAnalyticsOCRFailureReason(invalidReason);
    } else {
      logWarning(
        'OCR Quality Check failed as unknown with no reason provided by the backend'
      );
      failureReason = 'unknown';
    }
    return { successful, failureReason };
  };

  const handleEditedImage = async (image: string) => {
    if (!image) {
      logException(
        new Error(
          'attempted to edit image in OCR scanner upload flow, but no image found'
        ),
        {
          extra: {
            userId: AuthStore?.user?.id,
          },
        }
      );
      return;
    }
    const cardSide = hasEditedFirstImage ? 'back' : 'front';
    let qualityCheckSucceeded: boolean = false;
    let qualityCheckFailureReason:
      | AnalyticsOCRQualityCheckFailureReason
      | undefined;
    try {
      const qualityCheckResp = await doQualityCheck(image);
      qualityCheckSucceeded = qualityCheckResp.successful;
      qualityCheckFailureReason = qualityCheckResp.failureReason;
    } catch (e) {
      qualityCheckSucceeded = false;

      if (hasUploadTooLargeMessage(e)) {
        clearInputModeSelection(SelectorError.UPLOAD_FILE_TOO_LARGE);
        qualityCheckFailureReason = 'file_size_too_large';
        return;
      }
      clearInputModeSelection(SelectorError.CATCHALL_ERROR);
      if (!qualityCheckFailureReason) {
        logWarning('OCR Quality Check failed as unknown due to a caught error');
        logException(e);
      }
    } finally {
      trackEvent({
        name: 'OCR Quality Check Completed',
        properties: {
          patientUserId: AuthStore.user.id,
          cardSide,
          // lastSelectedInputType should always be non-null at this point since there was a file thats been added
          ocrType: lastSelectedInputType!,
          qualityCheckSucceeded,
          qualityCheckFailureReason: qualityCheckSucceeded
            ? 'no_failure'
            : (qualityCheckFailureReason ?? 'unknown'),
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
      setLastSelectedInputType(null);
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files![0];
    if (file) {
      setError('');

      if (!fileTypePermitted(file)) {
        setError('Please select an image file');
        return;
      }

      if (file.size > CLIENT_SIDE_FILE_SIZE_LIMIT) {
        clearInputModeSelection(SelectorError.UPLOAD_FILE_TOO_LARGE);
        trackEvent({
          name: 'OCR Quality Check Completed',
          properties: {
            patientUserId: AuthStore.user.id,
            cardSide: hasEditedFirstImage ? 'back' : 'front',
            // lastSelectedInputType should always be non-null at this point since there was a file thats been added
            ocrType: lastSelectedInputType!,
            qualityCheckSucceeded: false,
            qualityCheckFailureReason: 'file_size_too_large',
            insuranceFormContext: getAvoInsuranceFormContext(),
          },
        });
        return;
      }

      setIsWaitingOnImage(true);
      const reader = new FileReader();

      reader.onload = (e) => {
        setEditorCurrentImage(e.target?.result as string);
        setIsWaitingOnImage(false);
      };

      reader.onerror = () => {
        setError('Error reading file');
        setIsWaitingOnImage(false);
      };

      reader.readAsDataURL(file);
    } else {
      // TODO(OCR): not sure if it's possible to get here without a file,
      // but can set an error message as a guardrail
      setError('Please select a file.');
    }
  };

  const hasEditedFirstImage = !!editedFrontImage;
  const hasEditedSecondImage = !!editedBackImage;

  enum ImageUploadFlowState {
    CHECKING_READABILITY = 'CHECKING_READABILITY',
    AWAITING_FRONT_IMAGE = 'AWAITING_FRONT_IMAGE',
    EDITING_FRONT_IMAGE = 'EDITING_FRONT_IMAGE',
    AWAITING_BACK_IMAGE = 'AWAITING_BACK_IMAGE',
    EDITING_BACK_IMAGE = 'EDITING_BACK_IMAGE',
    LOADING_IMAGE = 'LOADING_IMAGE',
    AWAITING_INTERPRETATION = 'AWAITING_INTERPRETATION',
    SWITCH_TO_CAMERA = 'SWITCH_TO_CAMERA',
  }
  // represent the component's state for easier readability/comprehension
  let state = ImageUploadFlowState.AWAITING_FRONT_IMAGE;
  if (isCheckingReadability) {
    state = ImageUploadFlowState.CHECKING_READABILITY;
  } else if (isWaitingOnImage) {
    state = ImageUploadFlowState.LOADING_IMAGE;
  } else if (!editorCurrentImage && !hasEditedFirstImage) {
    state = ImageUploadFlowState.AWAITING_FRONT_IMAGE;
  } else if (!editorCurrentImage && !hasEditedSecondImage) {
    state = ImageUploadFlowState.AWAITING_BACK_IMAGE;
  } else if (editorCurrentImage && !hasEditedFirstImage) {
    state = ImageUploadFlowState.EDITING_FRONT_IMAGE;
  } else if (editorCurrentImage && !hasEditedSecondImage) {
    state = ImageUploadFlowState.EDITING_BACK_IMAGE;
  } else if (isWaitingOnInterpretationResponse) {
    state = ImageUploadFlowState.AWAITING_INTERPRETATION;
  }

  let lowerHalfContent = <></>;

  if (state === ImageUploadFlowState.CHECKING_READABILITY) {
    lowerHalfContent = checkingImageReadabilityContent;
  } else if (state === ImageUploadFlowState.LOADING_IMAGE) {
    lowerHalfContent = (
      <>
        <div className="flex h-64 items-center justify-center rounded-lg bg-gray-50">
          <div className="text-gray-500">Loading...</div>
        </div>
      </>
    );
  } else {
    lowerHalfContent = (
      <>
        {error && (
          <div className="mb-4 rounded-lg bg-red-100 p-3 text-sm text-red-700">
            {error}
          </div>
        )}
        {readabilityError && (
          <>
            <div className="flex flex-col gap-4" style={{ padding: 20 }}>
              <>
                <ContentText variant="section-title/medium">
                  <span css={{ fontWeight: theme.fontWeight.bold }}>
                    We're unable to read this image
                  </span>
                </ContentText>
                <ContentText>Please make sure:</ContentText>
                <NumberedList>
                  <NumberedListItem>
                    <ContentText variant="body-small">
                      The text on the card is sharp and readable
                    </ContentText>
                  </NumberedListItem>

                  <NumberedListItem>
                    <ContentText variant="body-small">
                      There's no glare, overly bright spots, or shadows
                    </ContentText>
                  </NumberedListItem>

                  <NumberedListItem>
                    <ContentText variant="body-small">
                      The entire card fits within the frame and is aligned with
                      the corners.
                    </ContentText>
                  </NumberedListItem>
                </NumberedList>
              </>
            </div>
            <div className="flex flex-col gap-4" css={BOTTOM_BUTTON_CSS}>
              <Button
                variant="primary"
                size="large"
                onPress={() => {
                  setEditorCurrentImage(null);
                  setReadabilityError(null);
                }}
                data-dd-action-name="OCR Upload Flow - Select different photo (after upload)"
              >
                <span className="flex items-center justify-center gap-2">
                  Select different photo
                </span>
              </Button>
              <div style={styles.textWithLines}>
                <span style={styles.line}></span>
                <span style={styles.text}>or</span>
                <span style={styles.line}></span>
              </div>
              <Button
                variant="secondary"
                size="large"
                onPress={redirectToManual}
                data-dd-action-name="OCR Upload Flow - Enter manually instead"
              >
                Enter manually
              </Button>
            </div>
          </>
        )}
        {editorCurrentImage && !readabilityError && (
          <div className="flex flex-col gap-4" style={{ padding: 20 }}>
            <>
              <Image
                src={
                  hasEditedFirstImage
                    ? framedInsuranceCardBack
                    : framedInsuranceCardFront
                }
                alt="Insurance card"
                css={{ width: window.innerHeight > 700 ? '40%' : '20%' }}
              />
              <span
                css={{ fontSize: '17px', fontWeight: theme.fontWeight.bold }}
              >
                Position the insurance card in the frame.
              </span>
              <span css={{ fontSize: '17px' }}>
                Pinch the image to rotate, scale and move it.
              </span>
            </>
            <div className="flex flex-col gap-4" css={BOTTOM_BUTTON_CSS}>
              <Button
                data-dd-action-name="OCR Upload Flow - edit looks good"
                onPress={() => {
                  processEditedImage().then((image) => {
                    if (image) {
                      handleEditedImage(image);
                    }
                  });
                }}
              >
                Looks good
              </Button>
              <Button
                variant="secondary"
                size="large"
                data-dd-action-name="OCR Upload Flow - select different photo (before upload)"
                onPress={() => setEditorCurrentImage(null)}
              >
                Select different photo
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }

  const awaitingImage =
    state === ImageUploadFlowState.AWAITING_BACK_IMAGE ||
    state === ImageUploadFlowState.AWAITING_FRONT_IMAGE;
  const awaitingImageContent = (
    <div className="flex flex-col gap-4" style={{ padding: 20 }}>
      <Image
        src={hasEditedFirstImage ? insuranceCardBack : insuranceCardFront}
        alt="Insurance card"
        sizes="(max-width: 768px) 375px, 850px"
        css={{ width: '100%' }}
      />
      <ContentText variant="body/medium">
        {hasEditedFirstImage ? 'Back' : 'Front'} side of your insurance card
      </ContentText>
      <ContentText>
        Ensure all four corners of the card are visible in the photo, and the
        image is well-lit and glare-free.
      </ContentText>
      <div className="flex flex-col gap-4" css={BOTTOM_BUTTON_CSS}>
        <Button
          data-dd-action-name="OCR Upload Flow - use camera"
          onPress={triggerCameraInput}
        >
          <span className="flex items-center justify-center gap-2">
            <Camera fill="#FFFFFF" width={24} height={24} />
            Take photo
          </span>
        </Button>
        <input
          type="file"
          id="ocrCameraInput"
          accept="image/*"
          capture="environment"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button
          data-dd-action-name="OCR Upload Flow - choose file"
          onPress={triggerFileInput}
          variant="secondary"
        >
          <span className="flex items-center justify-center gap-2">
            <ImageSquare fill="#000000" width={24} height={24} />
            Upload image
          </span>
        </Button>
      </div>
      <input
        type="file"
        id="ocrFileInput"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );

  const lowerHalf = (
    <div aria-label="Photo Instructions">{lowerHalfContent}</div>
  );

  const modalContent = (
    <>
      {awaitingImage && awaitingImageContent}
      {editorCurrentImage &&
        (state === ImageUploadFlowState.CHECKING_READABILITY ||
        readabilityError ? (
          <img
            src={editorCurrentImage}
            alt="Insurance card"
            css={{ width: '100%', height: '100%' }}
          />
        ) : (
          <InsuranceCardPhotoEditor
            image={editorCurrentImage}
            handleProcessEditedImage={handleProcessEditedImage}
          />
        ))}
    </>
  );
  const showLowerHalf = !awaitingImage;

  return isWaitingOnInterpretationResponse ? (
    <Modal
      aria-label="Extracting insurance card information"
      isOpen={true}
      variant="fullscreen"
      isDismissable={false}
      title=""
    >
      <ScanningCardScreen isMobile={true} />
    </Modal>
  ) : (
    <Modal
      title="Upload insurance card"
      isOpen={true}
      variant="fullscreen"
      onDismiss={clearInputModeSelection}
    >
      <ModalContent>
        {modalContent}
        {showLowerHalf && lowerHalf}
      </ModalContent>
    </Modal>
  );
};
const styles: { [key: string]: React.CSSProperties } = {
  textWithLines: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    alignContent: 'center',
    justifyContent: 'center',
  },
  line: {
    display: 'block',
    width: '100%',
    height: '2px',
    backgroundColor: `${HelixTheme.color.system.borderGray}`,
  },
  text: {
    paddingRight: '35px',
    paddingLeft: '35px',
  },
};

const InsuranceInterpretationImageUploadFlow = withStores(
  InsuranceInterpretationImageUploadFlowCore
);
export default InsuranceInterpretationImageUploadFlow;
