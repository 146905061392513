import React, { useCallback, useRef, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';

import { ArrowClockwise } from '@headway/icons';
import { INSURANCE_CARD_ASPECT_RATIO } from '@headway/shared/constants/insuranceLookup';

const INITIAL_HORIZONTAL_ZOOM = 1.4;
const INITIAL_VERTICAL_ZOOM = 3;
const INITIAL_CROP_AREA = {
  width: 337,
  height: 212.5,
};
const CROP_AREA_LATERAL_PADDING = 20;

/**
 * imageUrl is the Base64-encoded string starting with "data:image/..."
 */
type imageUrl = string;
export type InsuranceCardPhotoEditorProps = {
  /**
   * original input image source
   */
  image: imageUrl;
  /**
   * callback function when we want to process the edited image, returns the edited image string
   */
  handleProcessEditedImage: (
    updateFn: () => Promise<imageUrl | undefined>
  ) => void;
};

const createImage = (url: any): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

/**
 * pulled from function w/ same name in react-easy-crop demo: https://codesandbox.io/p/sandbox/y09komm059
 */
const getRadianAngle = (degreeValue: number) => {
  return (degreeValue * Math.PI) / 180;
};

/**
 * Returns the new bounding area of a rotated rectangle.
 * pulled from function w/ same name in react-easy-crop demo: https://codesandbox.io/p/sandbox/y09komm059
 */
const rotateSize = (width: number, height: number, rotation: number) => {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
};

/**
 *
 * pulled from similar function in react-easy-crop demo: https://codesandbox.io/p/sandbox/y09komm059
 * (see getCroppedImg)
 */
const getEditedImg = async (
  imageSrc: imageUrl,
  pixelCrop: Area,
  rotation: number = 0,
  flip = { horizontal: false, vertical: false }
): Promise<string | null> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement('canvas');

  const croppedCtx = croppedCanvas.getContext('2d');

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      if (!file) {
        reject(new Error('croppedCanvas was not converted correctly to blob'));
        return;
      }
      resolve(URL.createObjectURL(file));
    }, 'image/png');
  });
};

const InsuranceCardPhotoEditor: React.FC<InsuranceCardPhotoEditorProps> = ({
  image,
  handleProcessEditedImage,
}) => {
  const elementRef = useRef(null);
  const [editorImage, setEditorImage] = useState<imageUrl>(image);
  // Crop state
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [cropAreaSize, setCropAreaSize] = useState(INITIAL_CROP_AREA);
  const onCropperMediaLoaded = useCallback((mediaSize: any) => {
    // there is some white space to the left and the right of image in order to let users crop the edges of the photos
    // the default zoom is to not focus on those white spaces when the component is first loaded
    setZoom(
      mediaSize.width > mediaSize.height
        ? INITIAL_HORIZONTAL_ZOOM
        : INITIAL_VERTICAL_ZOOM
    );
  }, []);

  const handleRotate = () => {
    setRotation((prev) => (prev + 90) % 360);
  };

  const onCropComplete = useCallback((_: any, newCroppedAreaPixels: Area) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  }, []);

  const handleEditedImage = useCallback(async (): Promise<
    string | undefined
  > => {
    if (!croppedAreaPixels) {
      throw new Error(' no croppedAreaPixels');
    }

    const editedImage = await getEditedImg(
      editorImage,
      croppedAreaPixels,
      rotation
    );
    if (!editedImage) {
      throw new Error('unable to get edited image');
    }

    setEditorImage(editedImage);

    // Reset transforms
    setZoom(1);
    setRotation(0);
    setCrop({ x: 0, y: 0 });
    setCroppedAreaPixels(null);
    return editedImage;
  }, [editorImage, croppedAreaPixels, rotation]);

  React.useEffect(() => {
    handleProcessEditedImage(handleEditedImage);
  }, [handleProcessEditedImage, handleEditedImage]);

  React.useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const width =
      // @ts-ignore (ignoring because elementRef.current is typed as never even though that is not true)
      elementRef.current.clientWidth - CROP_AREA_LATERAL_PADDING * 2;
    const currentCropAreaSize = {
      width,
      height: width / INSURANCE_CARD_ASPECT_RATIO,
    };
    setCropAreaSize(currentCropAreaSize);
  }, [elementRef.current]);

  return (
    <div className="relative h-[40vh] w-full">
      <div
        className="w-full"
        ref={elementRef}
        style={{
          maxWidth: '100%',
        }}
      >
        <Cropper
          image={editorImage}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={INSURANCE_CARD_ASPECT_RATIO}
          cropSize={cropAreaSize}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onMediaLoaded={onCropperMediaLoaded}
          onZoomChange={setZoom}
          cropShape="rect"
          showGrid={false}
          style={{
            containerStyle: {
              width: '100%',
              height: '100%',
              backgroundColor: 'none',
            },
            mediaStyle: {
              backgroundColor: 'none',
            },
            cropAreaStyle: {
              borderRadius: '10px',
            },
          }}
        />
      </div>
      <button
        className="hlx-typography-content-body-medium absolute bottom-0 left-1/2 -translate-x-1/2 -translate-y-1/2"
        style={{
          backgroundColor: 'transparent',
          color: 'white',
          border: 'none',
          height: '38px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
        onClick={handleRotate}
      >
        <div>
          <ArrowClockwise
            fill="white"
            width={10}
            height={10}
            css={{ color: 'white' }}
          />{' '}
          Rotate photo
        </div>
      </button>
    </div>
  );
};

export default InsuranceCardPhotoEditor;
