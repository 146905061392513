import { getLocalTimeZone, today } from '@internationalized/date';
import { Formik, FormikProps } from 'formik';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import { CarrierLineOfBusiness } from '@headway/api/models/CarrierLineOfBusiness';
import { ErrorCode } from '@headway/api/models/ErrorCode';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { useFlag } from '@headway/feature-flags/react';
import { Button as HelixButton } from '@headway/helix/Button';
import { CollapsibleDisclosure } from '@headway/helix/CollapsibleDisclosure';
import { ComboBox, Item as ComboBoxItem } from '@headway/helix/ComboBox';
import { ContentText } from '@headway/helix/ContentText';
import { DateField } from '@headway/helix/DateField';
import { Form as HelixForm } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconQuestion } from '@headway/helix/icons/Question';
import { IconSealCheck } from '@headway/helix/icons/SealCheck';
import { Link } from '@headway/helix/Link';
import { PhoneNumberField } from '@headway/helix/MaskedTextField';
import {
  Modal as HelixModal,
  ModalContent,
  ModalFooter,
} from '@headway/helix/Modal';
import { Radio } from '@headway/helix/Radio';
import { RadioCardGroup } from '@headway/helix/RadioGroup';
import { TextField } from '@headway/helix/TextField';
import { YUP_PHONE_MATCH } from '@headway/shared/constants/format';
import unitedStatesDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  FRONT_END_CARRIERS_EXCLUSION_LIST,
  MEDICAID_PLAN_DETECTION,
} from '@headway/shared/FeatureFlags/flagNames';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { useSearchableFrontEndCarriersQuery } from '@headway/shared/hooks/useSearchableFrontEndCarriersQuery';
import {
  InsuranceCaptureProps,
  InsuranceLookupFormError,
  InsuranceLookupFormPage,
  InsuranceLookupFormValues,
} from '@headway/shared/types/insuranceCapture';
import { trackEvent } from '@headway/shared/utils/analytics';
import {
  getAvoInsuranceFormContext,
  getFilteredLookupFormCarrierIdList,
  getInFormCarrierWarningStatus,
  getInsuranceLookupFormPageFromPageUrl,
  InFormCarrierWarningStatus,
  LOOKUP_VALIDATION,
  MemoizedCarriersToOptionsForStateHelper,
  performLookup,
  PerformLookupErrors,
} from '@headway/shared/utils/InsuranceLookupUtils';
import { getIsUserInsuranceMedicareOrMedicaid } from '@headway/shared/utils/insuranceUtils';
import {
  categorizeLookupError,
  getErrorMessageFromCode,
  getErrorMessageFromString,
  getGenericLookupErrorMessage,
  LookupErrorCategory,
  shouldShowLookupErrors,
} from '@headway/shared/utils/lookupErrors';
import { formatPatientName } from '@headway/shared/utils/patient';
import { isProviderUser } from '@headway/shared/utils/users';
import { LogoLoaderWithText } from '@headway/ui/LogoLoaderWithText';
import { MemberIdValidationGuidance } from '@headway/ui/MemberIdValidationGuidance';

import { withStores } from '../../stores/withStores';
import { ConfirmProviderLookupModal } from '../Benefits/InsuranceModals/ConfirmProviderLookupModal';
import BookAppointmentHelixVariantHeadline from '../BookAppointmentFlow/components/BookAppointmentHelixVariantHeadline';
import CarrierNotInStateFormHelperText from './CarrierWarningComponents/CarrierNotInStateFormHelperText';
import CarrierRequiringAuthorizationGuidanceCard from './CarrierWarningComponents/CarrierRequiringAuthorizationGuidanceCard';
import ProviderDoesNotTakePlanFormHelperText from './CarrierWarningComponents/ProviderDoesNotTakePlanFormHelperText';

const todaysCalendarDate = today(getLocalTimeZone());

// Store whether special characters exist in first/last name fields so we can
//   track them once per distinct set of special characters and not on every keypress
let prevFirstNameSpecialCharacters: string | null = null;
let prevLastNameSpecialCharacters: string | null = null;
const getFormSchema = (
  user: UserRead,
  {
    isMedicaidPlanDetectionEnabled,
  }: { isMedicaidPlanDetectionEnabled: boolean }
) => {
  return Yup.object().shape({
    firstName: LOOKUP_VALIDATION.fields.basicFirstName.concat(
      Yup.string().test(
        'no-special-characters',
        'Don’t include any special characters or numbers, write exactly what’s printed on your insurance card.',
        (value: string) => {
          const specialCharacters = (value || '').match(/[^a-zA-Z'. -]/g);
          if (!!specialCharacters) {
            // only track the event when the special characters change
            if (prevFirstNameSpecialCharacters !== specialCharacters.join('')) {
              prevFirstNameSpecialCharacters = specialCharacters.join('');
              trackEvent({
                name: 'Add Insurance Inline Error Surfaced',
                properties: {
                  patientUserId: user.id,
                  insuranceElement: 'first_name',
                  insuranceElementErrorDescription: 'invalid_character',
                  insuranceElementErrorString: specialCharacters,
                  insuranceSubmissionBlocked: true,
                  insuranceFormContext: getAvoInsuranceFormContext(),
                },
              });
            }
            return false; // Validation failed
          }
          prevFirstNameSpecialCharacters = null;
          return true; // Validation passed
        }
      )
    ),
    lastName: LOOKUP_VALIDATION.fields.basicLastName.concat(
      Yup.string().test(
        'no-special-characters',
        'Don’t include any special characters or numbers, write exactly what’s printed on your insurance card.',
        (value: string) => {
          const specialCharacters = (value || '').match(/[^a-zA-Z'. -]/g);
          if (!!specialCharacters) {
            if (prevLastNameSpecialCharacters !== specialCharacters.join('')) {
              prevLastNameSpecialCharacters = specialCharacters.join('');
              trackEvent({
                name: 'Add Insurance Inline Error Surfaced',
                properties: {
                  patientUserId: user.id,
                  insuranceElement: 'last_name',
                  insuranceElementErrorDescription: 'invalid_character',
                  insuranceElementErrorString: specialCharacters,
                  insuranceSubmissionBlocked: true,
                  insuranceFormContext: getAvoInsuranceFormContext(),
                },
              });
            }
            return false; // Validation failed
          }
          prevLastNameSpecialCharacters = null;
          return true; // Validation passed
        }
      )
    ),
    phone: user.phone
      ? Yup.string().notRequired()
      : Yup.string()
          .test(
            'required',
            'Phone number is required.',
            (val) => (val || '').replace(/[^0-9]/g, '').length > 0
          )
          .matches(YUP_PHONE_MATCH, 'Phone number must be 10 digits.'),
    email: Yup.string().email('Please provide a valid email address.'),
    dob: LOOKUP_VALIDATION.fields.dob,
    frontEndCarrierId: LOOKUP_VALIDATION.fields.carrierId,
    memberId: LOOKUP_VALIDATION.fields.memberId,
    groupNumber: LOOKUP_VALIDATION.fields.groupNumber,
    lastSearchedState: LOOKUP_VALIDATION.fields.state,
    isMedicareOrMedicaid: isMedicaidPlanDetectionEnabled
      ? Yup.string()
          .oneOf(['true', 'false'])
          .required(
            'Please specify if your plan is a Medicare or Medicaid plan.'
          )
      : Yup.string().oneOf(['true', 'false']).notRequired(),
  });
};

type State = {
  carriers: FrontEndCarrierRead[];
  carriersById: Record<number, FrontEndCarrierRead>;
  isCheckingEligibility: boolean;
  isSecondaryPlanModalOpen: boolean;
  isConfirmProviderLookupModalOpen: boolean;
  refreshedEligibilityLookupErrors?: {
    otherErrors: Array<string>;
    errorCodes: Array<ErrorCode>;
  };
};

interface RefreshedEligibilityLookupErrors {
  otherErrors: Array<string>;
  errorCodes: Array<ErrorCode>;
}

const InsuranceLookupFormManualInputImpl = (props: InsuranceCaptureProps) => {
  const isMedicaidPlanDetectionEnabled = useFlag(
    MEDICAID_PLAN_DETECTION,
    false
  );
  const { user } = props.AuthStore;
  const carriersToOptionsForStateHelper: MemoizedCarriersToOptionsForStateHelper =
    new MemoizedCarriersToOptionsForStateHelper();

  const { frontEndCarriers: carriers = [] } =
    useSearchableFrontEndCarriersQuery(false);

  /*
      TODO(Benefits/Risa): Should be able to refactor to:
        const frontEndCarriersExclusionList = props.frontEndCarriersExclusionList ?? [];
      once we release medicaid plan detection and remove medicare and medicaid
      carrier ids from the exclusion list in LD
  */
  const medicareMedicaidCarrierIds = carriers.reduce((acc, carrier) => {
    if (
      carrier.lineOfBusiness === CarrierLineOfBusiness.MEDICAID ||
      carrier.lineOfBusiness === CarrierLineOfBusiness.MEDICARE
    ) {
      acc.push(carrier.id);
    }
    return acc;
  }, [] as number[]);

  const frontEndCarriersExclusionList = props.frontEndCarriersExclusionList
    ? isMedicaidPlanDetectionEnabled
      ? props.frontEndCarriersExclusionList.filter(
          (carrier) => !medicareMedicaidCarrierIds.includes(carrier)
        )
      : props.frontEndCarriersExclusionList
    : [];

  const filteredCarriers = getFilteredLookupFormCarrierIdList(
    carriers,
    frontEndCarriersExclusionList
  );
  const carriersById = keyBy(filteredCarriers, 'id');

  const [isSecondaryPlanModalOpen, setIsSecondaryPlanOpenModal] =
    useState<boolean>(false);
  const [
    isConfirmProviderLookupModalOpen,
    setIsConfirmProviderLookupModalOpen,
  ] = useState(false);
  const [isCheckingEligibility, setIsCheckingEligibility] =
    useState<boolean>(false);

  const [
    refreshedEligibilityLookupErrors,
    setRefreshedEligibilityLookupErrors,
  ] = useState<RefreshedEligibilityLookupErrors>({
    otherErrors: [],
    errorCodes: [],
  });

  useEffect(() => {
    checkAndHandleLookupErrorStates(
      props.initialLookupErrorStates,
      user.activeUserInsurance
    );
  }, []);

  const trackInsuranceCompletedEvent = (
    userInsurance: UserInsuranceRead | undefined
  ) => {
    if (userInsurance?.latestEligibilityLookup?.id) {
      trackEvent({
        name: 'Add Insurance Completed',
        properties: {
          confirmationPageType: 'manual',
          patientUserId: user.id,
          userInsuranceId: String(userInsurance.id),
          eligibilityLookupId: userInsurance.latestEligibilityLookup.id,
          providerId: props.providerId,
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
    }
  };

  // returns true if it passes error checking
  // and tracks insurance completed event if the error results in a terminal state (i.e. resubmission of form is not required)
  const checkAndHandleLookupErrorStates = (
    errorStates?: PerformLookupErrors,
    userInsurance?: UserInsuranceRead
  ) => {
    if (!errorStates) {
      return true;
    }
    if (errorStates.lookupErrorCodes || errorStates.lookupOtherErrors) {
      setRefreshedEligibilityLookupErrors({
        errorCodes: errorStates.lookupErrorCodes ?? [],
        otherErrors: errorStates.lookupOtherErrors ?? [],
      });
      return false;
    }

    if (
      errorStates.lookupFormErrors.includes(
        InsuranceLookupFormError.VERIFY_FUZZY_MATCH
      )
    ) {
      props.onError(InsuranceLookupFormError.VERIFY_FUZZY_MATCH);
      return false;
    }
    const relevantLookupFormErrors = errorStates.lookupFormErrors.filter(
      (error) => error !== InsuranceLookupFormError.VERIFY_FUZZY_MATCH
    );
    if (relevantLookupFormErrors.length > 0) {
      trackInsuranceCompletedEvent(userInsurance);
      props.onError(relevantLookupFormErrors[0]);
      return false;
    }

    if (errorStates.claimUnreadySecondaryPlan) {
      trackInsuranceCompletedEvent(userInsurance);
      setIsSecondaryPlanOpenModal(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (
    values: InsuranceLookupFormValues,
    shouldCheckEligibility: boolean,
    user: UserRead
  ) => {
    trackEvent({
      name: 'Add Insurance Submitted',
      properties: {
        confirmationPageType: 'manual',
        patientUserId: user.id,
        insuranceFormContext: getAvoInsuranceFormContext(),
      },
    });
    const resp = await performLookup({
      updateFields: {
        firstName: values.firstName,
        lastName: values.lastName,
        dob: values.dob,
        memberId: values.memberId,
        groupNumber: values.groupNumber,
        carrier: carriers.find(
          ({ id }) => id === Number(values.frontEndCarrierId)
        ),
        phone: values.phone,
        email: values.email,
        lastSearchedState: values.lastSearchedState,
      },
      shouldCheckEligibility,
      carriers: carriers,
      existingUser: user,
      setUpdatedFrontEndUser: (u: UserRead) => props.AuthStore.setUser(u),
      startedCheckingEligibility: () => setIsCheckingEligibility(true),
    });

    const passedErrorStatesCheck = checkAndHandleLookupErrorStates(
      resp.errorStates,
      resp.insurance
    );

    if (!passedErrorStatesCheck) {
      // setting false here to stop the loading spinner and to display error state on the form
      setIsCheckingEligibility(false);
      return;
    }

    if (!resp.insurance) {
      throw new Error('Unexpected missing insurance after performingLookup');
    }

    trackInsuranceCompletedEvent(resp.insurance);
    return resp.insurance;
  };

  const firstName = formatPatientName(user, {
    firstNameOnly: true,
  });

  const onSubmit = async (
    values: InsuranceLookupFormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ): Promise<void> => {
    if (props.onSubmit) props.onSubmit();
    try {
      const shouldCheckEligibility =
        !props.eligibilityPreCheck || props.eligibilityPreCheck(values);

      const insurance = await handleSubmit(
        values,
        shouldCheckEligibility,
        user
      );

      if (!insurance) {
        throw new Error('User insurance not created or updated.');
      }

      if (props.eligibilityPostCheck) {
        await props.eligibilityPostCheck(values, user);
      }

      props.onSuccess(insurance, values);
    } catch (error) {
      // TODO: Add logging
    } finally {
      setIsCheckingEligibility(false);
      setSubmitting(false);
    }
  };

  const isUserInsuranceMedicareOrMedicaid =
    getIsUserInsuranceMedicareOrMedicaid(user.activeUserInsurance);

  const validationSchema = getFormSchema(user, {
    isMedicaidPlanDetectionEnabled,
  });

  return (
    <>
      {!!props.includeHeading && (
        <>
          {/* Hide heading if loading indicator is showing */}
          {!(props.loadingOverride || isCheckingEligibility) && (
            <div className="flex flex-col gap-2 pb-5">
              <BookAppointmentHelixVariantHeadline>
                {firstName ? `${firstName}, l` : `L`}
                et’s help estimate your cost
              </BookAppointmentHelixVariantHeadline>
              <ContentText variant="body-large">
                We will provide you an <strong>instant price estimate</strong>{' '}
                if you are in network with us.
              </ContentText>
            </div>
          )}
        </>
      )}
      <Formik
        initialValues={
          {
            phone: user.phone || '',
            email: user.email || user.questionnaireEmail || '',
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            dob: moment(user.dob).toISOString() || null,
            frontEndCarrierId:
              String(user.activeUserInsurance?.frontEndCarrierId || '') ||
              undefined,
            memberId: user.activeUserInsurance?.memberId || '',
            groupNumber: user.activeUserInsurance?.groupNumber || '',
            frontEndCarrierName:
              user.activeUserInsurance?.frontEndCarrierId === -1
                ? user.activeUserInsurance?.frontEndCarrierName || ''
                : '',
            lastSearchedState: user.lastSearchedState || ('' as UnitedStates),
            isMedicareOrMedicaid:
              isUserInsuranceMedicareOrMedicaid?.toString() ?? '',
          } as InsuranceLookupFormValues
        }
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validateOnChange={false}
      >
        {(formik) => (
          <InsuranceLookupFormikForm
            formik={formik}
            insuranceLookupFormState={{
              carriers: filteredCarriers,
              carriersById: carriersById,
              isCheckingEligibility: isCheckingEligibility,
              isSecondaryPlanModalOpen: isSecondaryPlanModalOpen,
              isConfirmProviderLookupModalOpen:
                isConfirmProviderLookupModalOpen,
              refreshedEligibilityLookupErrors:
                refreshedEligibilityLookupErrors,
            }}
            insuranceLookupFormProps={props}
            carriersToOptionsForState={(carriers, state) =>
              carriersToOptionsForStateHelper.getCarrierIds(carriers, state)
            }
            openConfirmProviderLookupModal={() =>
              setIsConfirmProviderLookupModalOpen(true)
            }
            closeSecondaryPlanModal={() => {
              setIsSecondaryPlanOpenModal(false);
            }}
            closeConfirmProviderLookupModal={() => {
              setIsConfirmProviderLookupModalOpen(false);
            }}
            setRefreshedEligibilityLookupErrors={
              setRefreshedEligibilityLookupErrors
            }
          />
        )}
      </Formik>
    </>
  );
};

type InsuranceLookupFormikFormProps = {
  formik: FormikProps<InsuranceLookupFormValues>;
  insuranceLookupFormState: State;
  insuranceLookupFormProps: InsuranceCaptureProps;
  carriersToOptionsForState: (
    carriers: FrontEndCarrierRead[],
    state: UnitedStates
  ) => number[] | null;
  openConfirmProviderLookupModal: () => void;
  closeSecondaryPlanModal: () => void;
  closeConfirmProviderLookupModal: () => void;
  setRefreshedEligibilityLookupErrors: (
    errors: RefreshedEligibilityLookupErrors
  ) => void;
};

const Buttons = ({
  user,
  isProvider,
  insuranceLookupFormProps,
  isSubmitting,
  submitForm,
  inFormCarrierWarningStatuses,
  openConfirmProviderLookupModal,
}: {
  user: UserRead;
  isProvider: boolean;
  insuranceLookupFormProps: InsuranceCaptureProps;
  isSubmitting: boolean;
  submitForm: () => void;
  inFormCarrierWarningStatuses: InFormCarrierWarningStatus[];
  openConfirmProviderLookupModal: () => void;
}) => {
  const shouldRenderCancelButton = !!insuranceLookupFormProps.onCancel;
  const shouldRenderSkipButton = !!insuranceLookupFormProps.onSkip;
  const shouldRenderRemoveButton =
    !!insuranceLookupFormProps.onRemoveInsurance && !!user.activeUserInsurance;

  if (
    !shouldRenderCancelButton &&
    !shouldRenderSkipButton &&
    !shouldRenderRemoveButton
  ) {
    return (
      <SubmitButton
        insuranceLookupFormProps={insuranceLookupFormProps}
        isProvider={isProvider}
        isSubmitting={isSubmitting}
        submitForm={submitForm}
        inFormCarrierWarningStatuses={inFormCarrierWarningStatuses}
        openConfirmProviderLookupModal={openConfirmProviderLookupModal}
      />
    );
  }
  return (
    <div className={shouldRenderRemoveButton ? 'flex justify-between' : ''}>
      {shouldRenderRemoveButton && (
        <RemoveButton
          insuranceLookupFormProps={insuranceLookupFormProps}
          isSubmitting={isSubmitting}
        />
      )}
      <div className={'flex justify-end gap-2'}>
        {shouldRenderCancelButton ? (
          <CancelButton
            insuranceLookupFormProps={insuranceLookupFormProps}
            isSubmitting={isSubmitting}
          />
        ) : shouldRenderSkipButton ? (
          <SkipButton
            insuranceLookupFormProps={insuranceLookupFormProps}
            isSubmitting={isSubmitting}
          />
        ) : null}
        <SubmitButton
          insuranceLookupFormProps={insuranceLookupFormProps}
          isProvider={isProvider}
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          inFormCarrierWarningStatuses={inFormCarrierWarningStatuses}
          openConfirmProviderLookupModal={openConfirmProviderLookupModal}
        />
      </div>
    </div>
  );
};

const CancelButton = ({
  insuranceLookupFormProps,
  isSubmitting,
}: {
  insuranceLookupFormProps: InsuranceCaptureProps;
  isSubmitting: boolean;
}) => {
  return (
    <HelixButton
      variant="secondary"
      onPress={insuranceLookupFormProps.onCancel}
      disabled={isSubmitting}
    >
      {insuranceLookupFormProps.cancelButtonText || 'Cancel'}
    </HelixButton>
  );
};

const SkipButton = ({
  insuranceLookupFormProps,
  isSubmitting,
}: {
  insuranceLookupFormProps: InsuranceCaptureProps;
  isSubmitting: boolean;
}) => {
  return (
    <HelixButton
      variant="secondary"
      onPress={insuranceLookupFormProps.onSkip}
      disabled={isSubmitting}
    >
      {insuranceLookupFormProps.skipButtonText || 'Skip'}
    </HelixButton>
  );
};

const RemoveButton = ({
  insuranceLookupFormProps,
  isSubmitting,
}: {
  insuranceLookupFormProps: InsuranceCaptureProps;
  isSubmitting: boolean;
}) => {
  return (
    <HelixButton
      variant="secondary"
      onPress={insuranceLookupFormProps.onRemoveInsurance}
      disabled={isSubmitting}
    >
      {insuranceLookupFormProps.removeButtonText || 'Remove Insurance'}
    </HelixButton>
  );
};

const SubmitButton = ({
  isProvider,
  insuranceLookupFormProps,
  isSubmitting,
  submitForm,
  inFormCarrierWarningStatuses,
  openConfirmProviderLookupModal,
}: {
  isProvider: boolean;
  insuranceLookupFormProps: InsuranceCaptureProps;
  isSubmitting: boolean;
  submitForm: () => void;
  inFormCarrierWarningStatuses: InFormCarrierWarningStatus[];
  openConfirmProviderLookupModal: () => void;
}) => {
  const insuranceLookupFormPage = getInsuranceLookupFormPageFromPageUrl();
  const userIsProviderAndOnBenefitsHub =
    isProvider &&
    insuranceLookupFormPage === InsuranceLookupFormPage.BENEFITS_HUB;
  return (
    <HelixButton
      variant="primary"
      type={userIsProviderAndOnBenefitsHub ? 'button' : 'submit'}
      onPress={() => {
        if (userIsProviderAndOnBenefitsHub) {
          openConfirmProviderLookupModal();
        } else {
          submitForm();
        }
      }}
      disabled={
        isSubmitting ||
        inFormCarrierWarningStatuses.includes(
          InFormCarrierWarningStatus.CARRIERS_REQUIRING_AUTHORIZATION
        )
      }
      data-dd-action-name="Insurance Lookup : manual flow continue button clicked"
    >
      {insuranceLookupFormProps.submitButtonText ?? 'Continue'}
    </HelixButton>
  );
};

const InsuranceLookupFormikForm = ({
  formik,
  insuranceLookupFormState,
  insuranceLookupFormProps,
  carriersToOptionsForState,
  openConfirmProviderLookupModal,
  closeSecondaryPlanModal,
  closeConfirmProviderLookupModal,
  setRefreshedEligibilityLookupErrors,
}: InsuranceLookupFormikFormProps) => {
  const isMedicaidPlanDetectionEnabled = useFlag(
    MEDICAID_PLAN_DETECTION,
    false
  );
  const { values, setFieldValue, isSubmitting, errors, submitForm } = formik;
  const { user } = insuranceLookupFormProps.AuthStore;
  const isProvider = isProviderUser(user);
  const carrierIdToCarrier = insuranceLookupFormState.carriersById;
  const errorCodes: ErrorCode[] =
    insuranceLookupFormState.refreshedEligibilityLookupErrors?.errorCodes || [];
  const otherErrors: string[] =
    insuranceLookupFormState.refreshedEligibilityLookupErrors?.otherErrors ||
    [];
  const selectedCarrier =
    carrierIdToCarrier[Number(values.frontEndCarrierId)] ?? undefined;

  const showInsuranceAuthorizationInstructions =
    insuranceLookupFormProps.showInsuranceAuthorizationInstructions;

  const matchingProviderCarrierQueryResult =
    useMatchingProviderFrontEndCarrierQuery(
      {
        providerId: insuranceLookupFormProps.providerId!, // ! because query is disabled if providerId is undefined
        patientFrontEndCarrierId: Number(values.frontEndCarrierId),
        patientInsuranceMemberId: values.memberId,
        // values.lastSearchedState is typed as UnitedStates but can be '', which causes a 422
        appointmentState: values.lastSearchedState
          ? values.lastSearchedState
          : undefined,
        includeHiddenCarriers: false,
      },
      {
        enabled: !!insuranceLookupFormProps.providerId,
      }
    );
  const inFormCarrierWarningStatuses = getInFormCarrierWarningStatus({
    selectedCarrier: selectedCarrier,
    selectedState: values.lastSearchedState,
    selectedMemberId: values.memberId,
    providerCarriers: insuranceLookupFormProps.providerCarriers,
    matchingProviderCarrierQueryResult,
  });

  // Show special characters warning if either of the names contain a space, apostrophe, period or hyphen and we aren't
  // already showing an error for either name field
  const firstNameSpecialCharacters = values.firstName.match(/[ '.-]/g);
  const lastNameSpecialCharacters = values.lastName.match(/[ '.-]/g);
  const showSpecialCharactersWarning =
    ((!!firstNameSpecialCharacters && !errors?.firstName) ||
      (!!lastNameSpecialCharacters && !errors?.lastName)) &&
    !(
      errorCodes.length === 1 &&
      categorizeLookupError(errorCodes[0]) === LookupErrorCategory.NAME
    );

  // Refresh lookup errors when carrier changes
  useEffect(() => {
    if (setRefreshedEligibilityLookupErrors) {
      setRefreshedEligibilityLookupErrors({ errorCodes: [], otherErrors: [] });
    }
  }, [formik.values.frontEndCarrierId]);

  // Use useRef to store the previous special characters so we only log when special
  //  characters change
  const prevFirstNameSpecialCharactersRef = useRef<string | null>(null);
  const prevLastNameSpecialCharactersRef = useRef<string | null>(null);
  // break tracking first and last name into two separate functions for simplicity
  useEffect(() => {
    if (showSpecialCharactersWarning && !!firstNameSpecialCharacters) {
      if (
        prevFirstNameSpecialCharactersRef.current !==
        firstNameSpecialCharacters.join('')
      ) {
        prevFirstNameSpecialCharactersRef.current =
          firstNameSpecialCharacters.join('');
        trackEvent({
          name: 'Add Insurance Inline Error Surfaced',
          properties: {
            patientUserId: user.id,
            insuranceElement: 'first_name',
            insuranceElementErrorDescription: 'suspected_invalid_character',
            insuranceElementErrorString: [...firstNameSpecialCharacters],
            insuranceSubmissionBlocked: false,
            insuranceFormContext: getAvoInsuranceFormContext(),
          },
        });
      }
    } else {
      prevFirstNameSpecialCharactersRef.current = null;
    }
  }, [user.id, showSpecialCharactersWarning, firstNameSpecialCharacters]);

  useEffect(() => {
    if (showSpecialCharactersWarning && !!lastNameSpecialCharacters) {
      if (
        prevLastNameSpecialCharactersRef.current !==
        lastNameSpecialCharacters.join('')
      ) {
        prevLastNameSpecialCharactersRef.current =
          lastNameSpecialCharacters.join('');
        trackEvent({
          name: 'Add Insurance Inline Error Surfaced',
          properties: {
            patientUserId: user.id,
            insuranceElement: 'last_name',
            insuranceElementErrorDescription: 'suspected_invalid_character',
            insuranceElementErrorString: [...lastNameSpecialCharacters],
            insuranceSubmissionBlocked: false,
            insuranceFormContext: getAvoInsuranceFormContext(),
          },
        });
      }
    } else {
      prevLastNameSpecialCharactersRef.current = null;
    }
  }, [user.id, showSpecialCharactersWarning, lastNameSpecialCharacters]);

  const {
    [LookupErrorCategory.GENERIC]: shouldShowGenericLookupError,
    [LookupErrorCategory.SPECIFIC]: shouldShowSpecificLookupError,
    [LookupErrorCategory.NAME]: shouldShowNameLookupError,
    [LookupErrorCategory.MEMBER_ID]: shouldShowMemberIdLookupError,
    [LookupErrorCategory.DATE_OF_BIRTH]: shouldShowDOBLookupError,
    [LookupErrorCategory.MANUAL_VERIFICATION_REQUIRED]:
      shouldShowManualVerificationRequiredError,
  } = shouldShowLookupErrors({
    otherLookupErrors: otherErrors,
    lookupErrorCodes: errorCodes,
    formikErrors: errors,
    isManualVerificationRequired:
      !!user.activeUserInsurance?.latestEligibilityLookup
        ?.manualVerificationRequired,
  });
  useEffect(() => {
    if (
      user.activeUserInsurance?.latestEligibilityLookup?.id &&
      (shouldShowGenericLookupError ||
        shouldShowSpecificLookupError ||
        shouldShowNameLookupError ||
        shouldShowMemberIdLookupError ||
        shouldShowDOBLookupError)
    ) {
      let errorType:
        | 'generic'
        | 'specific'
        | 'name'
        | 'member_id'
        | 'date_of_birth';
      if (shouldShowSpecificLookupError) {
        errorType = 'specific';
      } else if (shouldShowNameLookupError) {
        errorType = 'name';
      } else if (shouldShowMemberIdLookupError) {
        errorType = 'member_id';
      } else if (shouldShowDOBLookupError) {
        errorType = 'date_of_birth';
      } else {
        errorType = 'generic';
      }
      trackEvent({
        name: 'Add Insurance Human Input Error Surfaced',
        properties: {
          patientUserId: user.id,
          userInsuranceId: String(user.activeUserInsuranceId),
          eligibilityLookupId:
            user.activeUserInsurance.latestEligibilityLookup.id,
          errorType: errorType,
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
    }
  }, [
    user.id,
    user.activeUserInsurance?.latestEligibilityLookup?.id,
    shouldShowGenericLookupError,
    shouldShowSpecificLookupError,
    shouldShowNameLookupError,
    shouldShowMemberIdLookupError,
    shouldShowDOBLookupError,
  ]);

  const nameLookupError = errorCodes.find(
    (errorCode) => categorizeLookupError(errorCode) === LookupErrorCategory.NAME
  );
  const dobLookupError = errorCodes.find(
    (errorCode) =>
      categorizeLookupError(errorCode) === LookupErrorCategory.DATE_OF_BIRTH
  );
  const memberIdLookupError = errorCodes.find(
    (errorCode) =>
      categorizeLookupError(errorCode) === LookupErrorCategory.MEMBER_ID
  );

  // unselected by default and we want to require user selection before enabling the rest of the form
  const hasAnsweredMedicareOrMedicaid =
    !!values.isMedicareOrMedicaid || !isMedicaidPlanDetectionEnabled;

  const { medicareAndMedicaidCarriers, commercialCarriers } = useMemo(
    () =>
      insuranceLookupFormState.carriers.reduce(
        (acc, carrier) => {
          if (
            carrier.lineOfBusiness === CarrierLineOfBusiness.MEDICAID ||
            carrier.lineOfBusiness === CarrierLineOfBusiness.MEDICARE
          ) {
            acc.medicareAndMedicaidCarriers.push(carrier);
          } else {
            acc.commercialCarriers.push(carrier);
          }
          return acc;
        },
        {
          medicareAndMedicaidCarriers: [] as FrontEndCarrierRead[],
          commercialCarriers: [] as FrontEndCarrierRead[],
        }
      ),
    [insuranceLookupFormState.carriers]
  );

  if (
    insuranceLookupFormState.isCheckingEligibility ||
    insuranceLookupFormProps.loadingOverride
  ) {
    return (
      <div className={'py-[80px]'}>
        <LogoLoaderWithText
          loadingTexts={[
            `Looking up your insurance details with ${selectedCarrier?.name}...`,
            'Translating confusing insurance lingo...',
            'Almost there...',
          ]}
        />
      </div>
    );
  }

  const carriers =
    values.isMedicareOrMedicaid === 'true'
      ? medicareAndMedicaidCarriers
      : commercialCarriers;

  const carrierOptions = carriersToOptionsForState(
    carriers,
    values.lastSearchedState
  );

  const isInsuranceInputsDisabled =
    isMedicaidPlanDetectionEnabled && !values.isMedicareOrMedicaid;

  // only render form once we have carriers to show
  if (carrierOptions?.length === 0) {
    return (
      <div className={'py-[80px]'}>
        <LogoLoaderWithText />
      </div>
    );
  }

  const onToggleMedicareAndMedicaidDisclosure = (expanded: boolean) => {
    if (expanded) {
      trackEvent({
        name: 'What Are Medicare and Medicaid Clicked',
        properties: {
          patientUserId: user.id,
          experimentNames: ['medicare_and_medicaid_disclosure'],
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
    }
  };

  return (
    <div className="flex flex-col gap-8">
      {(shouldShowGenericLookupError ||
        shouldShowSpecificLookupError ||
        shouldShowManualVerificationRequiredError) && (
        <div className="flex flex-col gap-4">
          {shouldShowSpecificLookupError ? (
            <GuidanceCard variant="error">
              <ContentText variant="body-small">
                {getErrorMessageFromString(otherErrors[0])
                  .split('\n')
                  .map((line, index) => (
                    <div key={index}>
                      {line}
                      <br />
                    </div>
                  ))}
              </ContentText>
            </GuidanceCard>
          ) : shouldShowManualVerificationRequiredError ? (
            <GuidanceCard variant="warning" layout="vertical">
              <ContentText variant="body/medium">
                Your benefits need additional verification
              </ContentText>
              <p>
                <ContentText>
                  Please double check that your insurance details match your
                  insurance card exactly. If everything looks good, start an
                  additional verification with us.
                </ContentText>
              </p>
              <Link href="/contact" target="_blank">
                Start additional verification
              </Link>
            </GuidanceCard>
          ) : (
            shouldShowGenericLookupError && (
              <GuidanceCard variant="error">
                <ContentText variant="body-small">
                  {getGenericLookupErrorMessage(selectedCarrier?.name)}
                </ContentText>
              </GuidanceCard>
            )
          )}
        </div>
      )}
      <HelixForm>
        <HelixModal
          title="Is this your secondary insurance plan?"
          isOpen={insuranceLookupFormState.isSecondaryPlanModalOpen}
        >
          <ModalContent>
            <ContentText>
              We can't send claims to secondary insurance plans at this time.
              Please enter details for your primary insurance to continue
              booking.
            </ContentText>
          </ModalContent>
          <ModalFooter>
            <HelixButton
              variant="primary"
              onPress={() => {
                setFieldValue('frontEndCarrierId', undefined);
                setFieldValue('memberId', '');
                setFieldValue('phone', '');
                closeSecondaryPlanModal();
              }}
              data-dd-action-name="Insurance Lookup : add primary insurance button clicked"
            >
              Add primary insurance
            </HelixButton>
          </ModalFooter>
        </HelixModal>
        <ConfirmProviderLookupModal
          user={user}
          isOpen={insuranceLookupFormState.isConfirmProviderLookupModalOpen}
          onDismiss={closeConfirmProviderLookupModal}
          onSubmit={() => {
            closeConfirmProviderLookupModal();
            submitForm();
          }}
        />
        <div className="text-initial flex flex-col gap-5">
          {(!values.firstName ||
            !values.lastName ||
            errors.firstName ||
            errors.lastName ||
            showSpecialCharactersWarning ||
            shouldShowNameLookupError) && (
            <div className="desktop:flex-row flex flex-col items-start gap-3">
              <div className="w-full flex-1">
                <FormControl
                  name="firstName"
                  aria-describedby="first-last-helptext"
                  component={TextField}
                  label="Legal first name"
                />
              </div>
              <div className="w-full flex-1">
                <FormControl
                  name="lastName"
                  aria-describedby="first-last-helptext"
                  component={TextField}
                  label="Legal last name"
                />
              </div>
            </div>
          )}
          {shouldShowNameLookupError && nameLookupError && (
            <GuidanceCard variant="error" layout="vertical">
              <ContentText variant="body-small">
                {getErrorMessageFromCode(nameLookupError)}
              </ContentText>
            </GuidanceCard>
          )}
          {showSpecialCharactersWarning && (
            <GuidanceCard variant="warning" layout="vertical">
              <ContentText>
                Some names contain special characters (hyphens, apostrophes,
                etc.) but please double check this matches the spelling on your
                card.
              </ContentText>
            </GuidanceCard>
          )}

          {!user.email && (
            <FormControl
              name="email"
              aria-describedby="email-helptext"
              component={TextField}
              label="Email"
            />
          )}

          <FormControl
            name="lastSearchedState"
            label="State"
            aria-describedby="state-helptext"
            component={ComboBox}
            selectionMode="single"
          >
            {sortBy(Object.values(UnitedStates)).map((s) => (
              <ComboBoxItem key={s}>{unitedStatesDisplayNames[s]}</ComboBoxItem>
            ))}
          </FormControl>

          {isMedicaidPlanDetectionEnabled && (
            <>
              <FormControl
                name="isMedicareOrMedicaid"
                label="Are you using a Medicare or Medicaid plan as your primary insurance?"
                component={RadioCardGroup}
                onChange={() => {
                  setFieldValue('frontEndCarrierId', undefined);
                }}
              >
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </FormControl>
              <CollapsibleDisclosure
                icon={<IconQuestion />}
                label="How do I know if I’m using Medicare or Medicaid?"
                onToggle={onToggleMedicareAndMedicaidDisclosure}
              >
                <ContentText>
                  Most members will have completed an application for Medicaid
                  or Medicare coverage through official state or federal
                  government channels (e.g.,
                  <Link
                    href="https://www.medicare.gov/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    medicare.gov
                  </Link>
                  ). Medicare Advantage plans will typically have "Medicare
                  Advantage" in the name, while Medicaid plans may go by many
                  different names. To verify your coverage type, it is best that
                  you call the number on the back of your insurance card. Please
                  note that Headway can only accept certain Medicare Advantage
                  or Managed Medicaid plans at this time.
                </ContentText>
              </CollapsibleDisclosure>
            </>
          )}

          <div>
            <FormControl
              name="frontEndCarrierId"
              label="Insurance"
              component={ComboBox}
              selectionMode="single"
              disabled={isInsuranceInputsDisabled}
              searchable
              filterStrategy="starts_with_and_contains"
            >
              {carrierOptions?.map((carrierIdString) => {
                const carrierId = Number(carrierIdString);
                const carrierName =
                  carrierIdToCarrier[Number(carrierId)]?.name || '';
                return (
                  <ComboBoxItem key={carrierId}>{carrierName}</ComboBoxItem>
                );
              })}
            </FormControl>
            {inFormCarrierWarningStatuses.includes(
              InFormCarrierWarningStatus.CARRIER_NOT_IN_STATE
            ) ? (
              <CarrierNotInStateFormHelperText
                carrier={selectedCarrier}
                state={values.lastSearchedState}
              ></CarrierNotInStateFormHelperText>
            ) : inFormCarrierWarningStatuses.includes(
                InFormCarrierWarningStatus.PROVIDER_DOES_NOT_TAKE_PLAN
              ) ? (
              <ProviderDoesNotTakePlanFormHelperText
                carrier={selectedCarrier}
              ></ProviderDoesNotTakePlanFormHelperText>
            ) : null}
          </div>

          {inFormCarrierWarningStatuses.includes(
            InFormCarrierWarningStatus.CARRIERS_REQUIRING_AUTHORIZATION
          ) && !!showInsuranceAuthorizationInstructions ? (
            <CarrierRequiringAuthorizationGuidanceCard
              carrier={selectedCarrier}
              insuranceAuthorizationPress={() =>
                showInsuranceAuthorizationInstructions(selectedCarrier!)
              }
              layout="vertical"
            ></CarrierRequiringAuthorizationGuidanceCard>
          ) : (
            <>
              <FormControl
                name="memberId"
                label="Member ID"
                component={TextField}
                optionalityText={
                  selectedCarrier &&
                  insuranceLookupFormProps.UiStore.canShowInsuranceHelperModal(
                    selectedCarrier.id
                  ) && (
                    <HelixButton
                      variant="link"
                      size="medium"
                      onPress={() =>
                        insuranceLookupFormProps.UiStore.showInsuranceHelperModal(
                          selectedCarrier.id
                        )
                      }
                    >
                      <ContentText
                        variant="caption/medium"
                        color={
                          isInsuranceInputsDisabled
                            ? 'foreground/disabled'
                            : 'foreground/secondary'
                        }
                      >
                        Need help finding it?
                      </ContentText>
                    </HelixButton>
                  )
                }
                disabled={isInsuranceInputsDisabled}
              />
              {hasAnsweredMedicareOrMedicaid && (
                <>
                  <MemberIdValidationGuidance
                    patient={user}
                    frontEndCarrierId={Number(values.frontEndCarrierId)}
                    memberId={values.memberId}
                    autoSpacing={false}
                    guidanceCardLayout="vertical"
                  />
                  {shouldShowMemberIdLookupError && memberIdLookupError && (
                    <GuidanceCard variant="error" layout="vertical">
                      <ContentText variant="body-small">
                        {getErrorMessageFromCode(memberIdLookupError)}
                      </ContentText>
                    </GuidanceCard>
                  )}
                </>
              )}
              <FormControl
                name="groupNumber"
                label="Group ID"
                helpText="Leave this blank if you don’t have one"
                component={TextField}
                disabled={isInsuranceInputsDisabled}
              />
            </>
          )}

          <FormControl
            name="dob"
            component={DateField}
            label="Date of birth"
            maxValue={todaysCalendarDate}
            disabled={isInsuranceInputsDisabled}
          />
          {shouldShowDOBLookupError && dobLookupError && (
            <GuidanceCard variant="error" layout="vertical">
              <ContentText variant="body-small">
                {getErrorMessageFromCode(dobLookupError)}
              </ContentText>
            </GuidanceCard>
          )}
          {!user.phone && (
            <FormControl
              name="phone"
              id="insurancePhone"
              label="Phone number"
              component={PhoneNumberField}
              disabled={isInsuranceInputsDisabled}
            />
          )}
        </div>
        <Buttons
          user={user}
          isProvider={isProvider}
          insuranceLookupFormProps={insuranceLookupFormProps}
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          inFormCarrierWarningStatuses={inFormCarrierWarningStatuses}
          openConfirmProviderLookupModal={openConfirmProviderLookupModal}
        />
        {insuranceLookupFormProps.includeFooter && (
          <div className="flex max-w-[335px] flex-col items-center gap-2 self-center py-12 text-center">
            <IconSealCheck />
            <ContentText variant="body-large">
              Most of our patients find their final cost matches our estimate.
            </ContentText>
          </div>
        )}
      </HelixForm>
    </div>
  );
};

const InsuranceLookupFormManualInputImplWithFlags = (
  props: InsuranceCaptureProps
) => {
  // Check the EXCLUDE_SPECIFIC variation of this feature flag to disable specific front end carriers from appearing in the insurance lookup modal dropdown.
  const frontEndCarriersExclusionList = useFlag(
    FRONT_END_CARRIERS_EXCLUSION_LIST,
    []
  );

  return (
    <InsuranceLookupFormManualInputImpl
      {...props}
      frontEndCarriersExclusionList={frontEndCarriersExclusionList}
    />
  );
};

export const InsuranceLookupFormManualInput = withStores(
  InsuranceLookupFormManualInputImplWithFlags
);
