import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { InterpretInsuranceCardResponse } from '@headway/api/models/InterpretInsuranceCardResponse';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { useFlag } from '@headway/feature-flags/react';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { Link as HelixLink } from '@headway/helix/Link';
import { NumberedList, NumberedListItem } from '@headway/helix/List';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { KAISER_PERMANENTE_NORCAL_IDS } from '@headway/shared/constants/carrierIds';
import {
  ENABLE_DESKTOP_OCR_INSURANCE_CHECKOUT_FLOW,
  ENABLE_MOBILE_OCR_INSURANCE_CHECKOUT_FLOW,
} from '@headway/shared/FeatureFlags/flagNames';
import {
  InsuranceCaptureProps,
  InsuranceLookupFormValues,
  InsuranceLookupInputMode,
  SelectorError,
} from '@headway/shared/types/insuranceCapture';
import { trackEvent } from '@headway/shared/utils/analytics';
import {
  getAvoInsuranceFormContext,
  PerformLookupErrors,
} from '@headway/shared/utils/InsuranceLookupUtils';

import { withStores } from '../../stores/withStores';
import InsuranceInterpretationCameraCaptureFlow from './InsuranceInterpretationCameraCaptureFlow';
import InsuranceInterpretationImageUploadFlow from './InsuranceInterpretationImageUploadFlow';
import { InsuranceLookupFormManualInput } from './InsuranceLookupFormManualInput';
import { InsuranceLookupInputModeSelector } from './InsuranceLookupInputModeSelector';
import { InsuranceLookupOCREntrypointDesktop } from './InsuranceLookupOCREntrypointDesktop';
import OCRInsuranceCardScannerConfirmation, {
  OCRInsuranceCardConfirmationValues,
} from './OCRInsuranceCardScannerConfirmation';

export const InsuranceAuthorizationInstructionsModalContent = ({
  carrier,
  closeModal,
}: {
  carrier: FrontEndCarrierRead;
  closeModal: () => void;
}) => {
  return (
    <>
      <ModalContent>
        <div className="gap-4">
          <ContentText>
            In order to book an appointment with a Headway therapist, you need
            to first get a referral from your local mental health clinic in the{' '}
            {carrier.name} network.
            <br />
            <br />
            Referrals aren’t guaranteed, but here’s how you can request one:
          </ContentText>
          <NumberedList>
            {carrier?.id &&
              KAISER_PERMANENTE_NORCAL_IDS.includes(carrier.id) && (
                <NumberedListItem>
                  <ContentText>
                    Visit{' '}
                    <HelixLink
                      rel="noreferrer"
                      target="_blank"
                      href="https://healthy.kaiserpermanente.org/northern-california/health-wellness/mental-health/services"
                    >
                      Kaiser’s mental health directory.
                    </HelixLink>
                  </ContentText>
                </NumberedListItem>
              )}
            <NumberedListItem>
              <ContentText>
                Find a clinic in your area and give them a call.
              </ContentText>
            </NumberedListItem>
            <NumberedListItem>
              <ContentText>
                Request a referral to “Headway” for mental health services. They
                will evaluate your care options and note your preference.
              </ContentText>
            </NumberedListItem>
            <NumberedListItem>
              <ContentText>
                If you receive the referral, Headway will email you a link to
                continue booking.
              </ContentText>
            </NumberedListItem>
          </NumberedList>
          <ContentText>
            If you think you already have a referral, give us a call at (510)
            495-1728.
          </ContentText>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button variant="primary" onPress={closeModal}>
          Got it
        </Button>
      </ModalFooter>
    </>
  );
};

const InsuranceLookupFormImplWithFlags = (props: InsuranceCaptureProps) => {
  const [inputMode, setInputMode] = useState<
    InsuranceLookupInputMode | undefined
  >();
  const [lookupErrors, setLookupErrors] = useState<
    PerformLookupErrors | undefined
  >();
  const [selectorError, setSelectorError] = useState<
    SelectorError | undefined
  >();
  const isMobile = useMediaQuery(theme.media.mobile);
  const mobileOcrInsuranceScanningEnabled: boolean = useFlag(
    ENABLE_MOBILE_OCR_INSURANCE_CHECKOUT_FLOW,
    false
  );
  const desktopOcrInsuranceScanningEnabled: boolean = useFlag(
    ENABLE_DESKTOP_OCR_INSURANCE_CHECKOUT_FLOW,
    false
  );

  const shouldShowDesktopOCR =
    desktopOcrInsuranceScanningEnabled &&
    !inputMode &&
    !isMobile &&
    props.enableOcr;

  const shouldShowInputModeSelector =
    mobileOcrInsuranceScanningEnabled && isMobile && props.enableOcr;

  const [interpretationResponse, setInterpretationResponse] =
    useState<InterpretInsuranceCardResponse | null>(null);

  // callback we invoke when we get an AI interpretation of insurance card images
  // from one of the image scanning paths (camera and file upload).
  const handleInterpretation = (
    interpretationResponse: InterpretInsuranceCardResponse
  ) => {
    setInterpretationResponse(interpretationResponse);
  };

  const setSelectorInputMode = (mode: InsuranceLookupInputMode) => {
    setInputMode(mode);
    const ocrTypeMapping: {
      [m in InsuranceLookupInputMode]: 'ocr' | 'manual';
    } = {
      [InsuranceLookupInputMode.MANUAL]: 'manual',
      [InsuranceLookupInputMode.UPLOAD]: 'ocr',
      [InsuranceLookupInputMode.CAMERA]: 'ocr', // TODO: PAT-799 delete when we delete camera flow
    };
    trackEvent({
      name: 'OCR Selector Button Clicked',
      properties: {
        patientUserId: props.AuthStore.user.id,
        addInsuranceUploadMethod: ocrTypeMapping[mode],
        insuranceFormContext: getAvoInsuranceFormContext(),
      },
    });
  };

  const setManualInputMode = () => {
    setInputMode(InsuranceLookupInputMode.MANUAL);
  };

  const clearInputModeSelection = (inputModeError?: SelectorError) => {
    setSelectorError(inputModeError);
    setInputMode(undefined);
  };

  useEffect(() => {
    if (props.overrideAsManual) {
      return;
    }
    trackEvent({
      name: 'Add Insurance Started',
      properties: {
        patientUserId: props.AuthStore.user.id,
        ocrFlowEnabled: shouldShowInputModeSelector || shouldShowDesktopOCR,
        providerId: props.providerId,
        insuranceFormContext: getAvoInsuranceFormContext(),
      },
    });
  }, []);

  useEffect(() => {
    if (props.overrideAsManual) {
      setManualInputMode();
    }
  }, [props.overrideAsManual]);

  // if we have an interpretation,
  // render the confirmation flow,
  // passing in a callback that will handle performing a request for an eligibility lookup.
  if (interpretationResponse && inputMode !== InsuranceLookupInputMode.MANUAL) {
    const cardInfo = {
      firstName: interpretationResponse?.insuranceCardInfo?.memberFirstName,
      lastName: interpretationResponse?.insuranceCardInfo?.memberLastName,
      carrierId:
        interpretationResponse?.insuranceCardInfo?.insuranceCarrier?.id,
      memberId: interpretationResponse?.insuranceCardInfo?.memberId,
      // even though its optional, it actually passes in null rather than undefined which is not good for the validator
      groupNumber:
        interpretationResponse?.insuranceCardInfo?.groupNumber ?? undefined,
    };

    const convertOCRToManualLookupFormValues = (
      values: OCRInsuranceCardConfirmationValues
    ): Omit<InsuranceLookupFormValues, 'email'> => {
      return {
        firstName: values.insuranceCardInfo.firstName,
        lastName: values.insuranceCardInfo.lastName,
        lastSearchedState: values.patientState,
        memberId: values.insuranceCardInfo.memberId,
        dob: values.dateOfBirth,
        groupNumber: values.insuranceCardInfo.groupNumber,
        frontEndCarrierId: values.insuranceCardInfo.carrierId.toString(),
      };
    };

    const submitOCRPreSteps = async (
      values: OCRInsuranceCardConfirmationValues
    ): Promise<boolean> => {
      if (props.onSubmit) props.onSubmit();

      return (
        !props.eligibilityPreCheck ||
        props.eligibilityPreCheck(convertOCRToManualLookupFormValues(values))
      );
    };

    const submitOCRPostSteps = async (
      values: OCRInsuranceCardConfirmationValues,
      errorStates: PerformLookupErrors | undefined,
      insurance: UserInsuranceRead | undefined
    ): Promise<void> => {
      if (errorStates) {
        setLookupErrors(errorStates);
        setInputMode(InsuranceLookupInputMode.MANUAL);
        return;
      }

      const manualLookupFormValues = convertOCRToManualLookupFormValues(values);
      if (props.eligibilityPostCheck) {
        await props.eligibilityPostCheck(
          manualLookupFormValues,
          props.AuthStore.user
        );
      }
      if (!insurance) {
        throw new Error(
          'Unexpected error, no insurance despite handling all expected error cases'
        );
      }
      props.onSuccess(insurance, manualLookupFormValues);
    };

    return (
      <OCRInsuranceCardScannerConfirmation
        initialInsuranceCardInfo={cardInfo}
        providerId={props.providerId}
        initialPatientState={props.AuthStore.user.lastSearchedState}
        providerCarriers={props.providerCarriers}
        submitPreSteps={submitOCRPreSteps}
        submitPostSteps={submitOCRPostSteps}
        showInsuranceAuthorizationFields={
          props.showInsuranceAuthorizationInstructions
        }
        insuranceCardInterpretationResultId={
          interpretationResponse.insuranceCardInterpretationResultId
        }
        isInsideModal={props.isInsideModal}
      />
    );
  }

  if (shouldShowDesktopOCR) {
    return (
      <InsuranceLookupOCREntrypointDesktop
        includeHeading={props.includeHeading}
        includeFooter={props.includeFooter}
        setModeSelection={setSelectorInputMode}
        selectorError={selectorError}
        handleInterpretation={handleInterpretation}
        clearInputModeSelection={clearInputModeSelection}
        isInsideModal={props.isInsideModal}
      />
    );
  }

  if (shouldShowInputModeSelector && !inputMode) {
    return (
      <InsuranceLookupInputModeSelector
        setModeSelection={setSelectorInputMode}
        selectorError={selectorError}
        includeHeading={props.includeHeading}
        includeFooter={props.includeFooter}
      />
    );
  }

  if (
    !shouldShowInputModeSelector ||
    inputMode === InsuranceLookupInputMode.MANUAL
  ) {
    return (
      <InsuranceLookupFormManualInput
        initialLookupErrorStates={lookupErrors}
        {...props}
      />
    );
  } else if (inputMode === InsuranceLookupInputMode.UPLOAD) {
    return (
      <InsuranceInterpretationImageUploadFlow
        handleInterpretation={handleInterpretation}
        clearInputModeSelection={clearInputModeSelection}
        redirectToManual={setManualInputMode}
      />
    );
  } else if (inputMode === InsuranceLookupInputMode.CAMERA) {
    return (
      <InsuranceInterpretationCameraCaptureFlow
        clearInputModeSelection={clearInputModeSelection}
        handleInterpretation={handleInterpretation}
        redirectToManual={setManualInputMode}
      />
    );
  } else {
    return <InsuranceLookupFormManualInput {...props} />;
  }
};
export const InsuranceLookupForm = withStores(InsuranceLookupFormImplWithFlags);
