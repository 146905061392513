import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

export const ConfirmProviderLookupModal = ({
  user,
  isOpen,
  onDismiss,
  onSubmit,
}: {
  user: UserRead;
  isOpen: boolean;
  onDismiss: () => void;
  onSubmit: () => void;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={`${user.activeUserInsuranceId ? 'Update' : 'Add'} your insurance?`}
    >
      <ModalContent>
        <ContentText>
          Submitting this form will update your personal insurance information
          on Headway. If you’re updating or adding a new client’s insurance,
          please do so in the Provider Portal.
        </ContentText>
      </ModalContent>
      <ModalFooter>
        <LinkButton
          href={`${process.env.NEXT_PUBLIC_SIGMUND_URL}/clients`}
          variant="secondary"
        >
          Go to Provider Portal
        </LinkButton>
        <Button type="submit" onPress={onSubmit} form="insurance-lookup-form">
          {user.activeUserInsuranceId ? 'Update' : 'Add'} my insurance
        </Button>
      </ModalFooter>
    </Modal>
  );
};
