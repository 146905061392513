import { FormHelperText } from '@mui/material';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import unitedStatesDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

const CarrierNotInStateFormHelperText = ({
  carrier,
  state,
}: {
  carrier?: FrontEndCarrierRead;
  state?: UnitedStates;
}) => {
  if (!carrier) {
    return null;
  }

  const stateStr = state ? `in ${unitedStatesDisplayNames[state]} ` : '';

  return (
    <FormHelperText variant="standard">
      We don't work with {carrier.name} {stateStr}yet. Unfortunately you won't
      be able to book a session with any of our providers, but you are welcome
      to browse their profiles.
    </FormHelperText>
  );
};

export default CarrierNotInStateFormHelperText;
