import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { INSURANCE_CARD_ASPECT_RATIO } from '@headway/shared/constants/insuranceLookup';
import { SelectorError } from '@headway/shared/types/insuranceCapture';

interface CameraWithOverlayProps {
  onImageCapture: (image: string) => void;
  handleCameraError: (inputModeError: SelectorError) => void;
  onCameraReady: (isReady: boolean) => void;
}

export interface CameraWithOverlayHandle {
  capturePhoto: () => void;
  clear: () => void;
  isCameraReady: boolean;
}

const CameraWithOverlay = React.forwardRef<
  CameraWithOverlayHandle,
  CameraWithOverlayProps
>(({ onImageCapture, handleCameraError, onCameraReady }, ref) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const borderRef = useRef<HTMLDivElement | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [displayedImageURL, setDisplayedImageURL] = useState<string | null>(
    null
  );
  const [isCameraReady, setIsCameraReady] = useState<boolean>(false);

  const getCameraStream = async () => {
    try {
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = videoStream;
      }
      setStream(videoStream);
    } catch (error) {
      const hasName = (error: unknown): error is { name: string } => {
        return Boolean(
          error &&
            typeof error === 'object' &&
            'name' in error &&
            typeof error.name === 'string'
        );
      };

      if (
        hasName(error) &&
        (error.name.includes('NotAllowedError') ||
          error.name.includes('PermissionsDeniedError'))
      ) {
        handleCameraError(SelectorError.CAMERA_PERMISSIONS_DENIED_ERROR);
        return;
      }
      handleCameraError(SelectorError.CAMERA_GENERIC_ERROR);
    }
  };

  useEffect(() => {
    getCameraStream();
    return () => {
      stream?.getTracks().forEach((track) => track.stop());
    };
  }, []);

  useEffect(() => {
    if (isCameraReady) {
      if (videoRef.current) {
        videoRef.current.play();
      }
      onCameraReady(true);
    } else {
      onCameraReady(false);
    }
  }, [isCameraReady]);

  const captureCroppedImage = (
    canvas: HTMLCanvasElement,
    border: HTMLDivElement,
    video: HTMLVideoElement,
    devicePixelRatio: number
  ) => {
    const borderRect = border.getBoundingClientRect();
    const videoRect = video.getBoundingClientRect();
    // Get the offset of the border relative to the video
    const borderX = borderRect.x - videoRect.x;
    const borderY = borderRect.y - videoRect.y;

    const borderWidth = borderRect.width;
    const borderHeight = borderRect.height;

    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;
    const context = canvas.getContext('2d');

    if (context) {
      canvas.width = borderWidth * devicePixelRatio;
      canvas.height = borderHeight * devicePixelRatio;

      const cropWidth = (borderWidth / videoRect.width) * videoWidth;
      const cropHeight = (borderHeight / videoRect.height) * videoHeight;
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(
        video,
        borderX,
        borderY,
        cropWidth,
        cropHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );

      canvas.toBlob((blob) => {
        if (blob) {
          const imageUrl = URL.createObjectURL(blob);
          onImageCapture(imageUrl);
        }
      }, 'image/png');
    }
  };

  const captureDisplayedImage = (
    canvas: HTMLCanvasElement,
    video: HTMLVideoElement,
    devicePixelRatio: number
  ) => {
    const context = canvas.getContext('2d');

    if (context) {
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;

      const zoomFactor = 0.85;

      const displayWidth = videoWidth * zoomFactor;
      const displayHeight = videoHeight * zoomFactor;

      const cropX = (videoWidth - displayWidth) / 2;
      const cropY = (videoHeight - displayHeight) / 2;

      canvas.width = displayWidth * devicePixelRatio;
      canvas.height = displayHeight * devicePixelRatio;
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(
        video,
        cropX,
        cropY,
        displayWidth,
        displayHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );
      canvas.toBlob((blob) => {
        if (blob) {
          const imageUrl = URL.createObjectURL(blob);
          setDisplayedImageURL(imageUrl);
        }
      }, 'image/png');
    }
  };
  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const border = borderRef.current;

    if (video && canvas && border) {
      const devicePixelRatio = window.devicePixelRatio || 1;

      captureCroppedImage(canvas, border, video, devicePixelRatio);
      captureDisplayedImage(canvas, video, devicePixelRatio);
    }
  };

  const clear = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setDisplayedImageURL(null);
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        context.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
      }
    }
    getCameraStream();
  };

  useImperativeHandle(ref, () => ({
    capturePhoto,
    clear,
    isCameraReady,
  }));

  return (
    <div style={styles.mainContainer}>
      {!displayedImageURL && (
        <div style={styles.cameraButtonContainer}>
          <div style={styles.cameraContainer}>
            <video
              ref={videoRef}
              autoPlay
              playsInline
              style={styles.video}
              onPlaying={() => setIsCameraReady(true)}
            />
            <div ref={borderRef} style={styles.border}></div>
          </div>
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
      )}
      {displayedImageURL && (
        <img
          src={displayedImageURL}
          alt="Captured"
          style={styles.imageContainer}
        />
      )}
    </div>
  );
});

const styles: { [key: string]: React.CSSProperties } = {
  mainContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  cameraContainer: {
    position: 'relative',
    width: '100%',
    margin: 'auto',
    overflow: 'hidden',
  },
  video: {
    width: '100%',
    height: '100%',
  },
  border: {
    position: 'absolute',
    aspectRatio: `${INSURANCE_CARD_ASPECT_RATIO.toString()}`,
    top: '10%',
    left: '10%',
    width: '80%',
    height: 'auto',
    border: '2px solid white',
    boxShadow: '0px 0px 0px 20000px rgba(0, 0, 0, 0.5)',
    borderRadius: '10px',
    pointerEvents: 'none',
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};

export default CameraWithOverlay;
